import {HStack, Icon, ListItem, Text,} from "@chakra-ui/react";
import {useEffect, useRef } from "react";
import {BsArrowReturnLeft} from "react-icons/bs";
import {useKeyPressEvent} from "react-use";

interface EntitySearchItemProps<T> {
  item: T;
  renderDescription: (item: T) => React.ReactNode;
  onClick: () => void;
  focusedIndex: number;
  index: number;
}

export function EntitySearchItem<T>({
  renderDescription,
  onClick,
  focusedIndex,
  index,
  item,
}: EntitySearchItemProps<T>) {
  const isFocused = focusedIndex === index;
  const itemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (!isFocused || !itemRef.current) return;
    
    itemRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [isFocused]);

  useKeyPressEvent("Enter", () => {
    if (isFocused) {
      onClick();
    }
  });

  return (
      <ListItem
          ref={itemRef}
          px={3}
          py={3}
          bg={isFocused ? "blackAlpha.50" : "transparent"}
          _hover={{ bg: "blackAlpha.50" }}
          _dark={{
            bg: isFocused ? "whiteAlpha.50" : "transparent",
            _hover: {
              bg: "whiteAlpha.50",
            },
          }}
          cursor="pointer"
          onClick={onClick}
          rounded="md"
      >
        <HStack justifyContent="space-between">
          <HStack>{renderDescription(item)}</HStack>
          {isFocused && (
              <HStack spacing={1}>
                <Text fontSize="xs">Enter</Text>
                <Icon as={BsArrowReturnLeft} h={3} />
              </HStack>
          )}
        </HStack>
      </ListItem>
  );
}
