import { Box, Flex, Icon, Text, Spacer } from "@chakra-ui/react";
import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import logo from "../logo/logo.png";
import { IconType } from "react-icons";
import { motion } from "framer-motion";
import { TERMS_TO_ACCEPT_BANNER_HEIGHT } from "terms-ui";
import { BETA_RELEASE_BANNER_HEIGHT } from "acsiss-ui";

export interface SidebarMenuLinkItem {
  title: string;
  to: string;
  icon: IconType;
  openInNewTab?: boolean;
}

interface SidebarMenuProps {
  linkItems: SidebarMenuLinkItem[];
  showAcceptTermsBanner: boolean;
  showBetaBanner: boolean;
}

function getLinkStyle(match: any): React.CSSProperties {
  return {
    color: match ? "white" : "#555",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 80,
    marginBottom: 16,
    paddingLeft: "8px",
    paddingRight: "8px",
    textAlign: "center",
  };
}

function NavLink({ title, to, icon }: SidebarMenuLinkItem) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      style={getLinkStyle(match)}
      to={to}
      data-testid={`sidebar-menu-item-${title}`}
    >
      <Icon fontSize="28px" as={icon} />
      <Text>{title}</Text>
    </Link>
  );
}

function BasicLink({ title, to, icon, openInNewTab }: SidebarMenuLinkItem) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <a
      style={getLinkStyle(match)}
      href={to}
      target={openInNewTab ? "_blank" : undefined}
      data-testid={`sidebar-menu-item-${title}`}
    >
      <Icon fontSize="28px" as={icon} />
      <Text>{title}</Text>
    </a>
  );
}

const SIDE_MENU_Y_OFFSET = 20;

export const getHeightOfBanners = ({
  showAcceptTermsBanner,
  showBetaBanner,
}: Pick<SidebarMenuProps, "showAcceptTermsBanner" | "showBetaBanner">) => {
  return [
    showAcceptTermsBanner ? TERMS_TO_ACCEPT_BANNER_HEIGHT : 0,
    showBetaBanner ? BETA_RELEASE_BANNER_HEIGHT : 0,
  ].reduce((acc, curr) => acc + curr, 0);
};

const resolveSideMenuHeight = ({
  showAcceptTermsBanner,
  showBetaBanner,
}: Pick<SidebarMenuProps, "showAcceptTermsBanner" | "showBetaBanner">) => {
  const heightOfBanners = getHeightOfBanners({
    showAcceptTermsBanner,
    showBetaBanner,
  });
  const yOffset = SIDE_MENU_Y_OFFSET * 2;
  return `calc(100vh - ${heightOfBanners + yOffset}px)`;
};

export const resolveTop = ({
  showAcceptTermsBanner,
  showBetaBanner,
}: Pick<SidebarMenuProps, "showAcceptTermsBanner" | "showBetaBanner">) => {
  const heightOfBanners = getHeightOfBanners({
    showAcceptTermsBanner,
    showBetaBanner,
  });
  return `${heightOfBanners + SIDE_MENU_Y_OFFSET}px`;
};

export const SidebarMenu = ({
  linkItems,
  showAcceptTermsBanner,
  showBetaBanner,
}: SidebarMenuProps) => (
  <Flex
    pos="sticky"
    left="5"
    top={resolveTop({ showAcceptTermsBanner, showBetaBanner })}
    h={resolveSideMenuHeight({ showAcceptTermsBanner, showBetaBanner })}
    w={"133px"}
    boxShadow="2xl"
    borderRadius={"30px"}
    bg="brand.500"
    display={{
      base: "none",
      md: "block",
    }}
    overflowY="hidden"
    py={5}
    px={1}
  >
    <Flex
      flexDir="column"
      justifyContent="space-between"
      h="full"
      overflowY="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          display: "none",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "rgb(240,191,76)",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }}
    >
      <Box>
        <Box>
          <Link to="/">
            <Flex height="140px" justifyContent="center" alignItems="center">
              <img src={logo} alt="Logo" style={{ height: 40, width: 40 }} />
            </Flex>
          </Link>
        </Box>
        {linkItems.map((l) => {
          if (l.to === "https://help.acsiss.com.au") {
            return <BasicLink key={l.to} {...l} openInNewTab={true} />;
          }
          if (l.to.startsWith("mailto:")) {
            return <BasicLink key={l.to} {...l} />;
          }
          return <NavLink key={l.to} {...l} />;
        })}
      </Box>
      <Spacer />
      <Box textAlign="center" fontSize={12} color="rgba(0, 0, 0, 0.5)" pb={4}>
        <Text>Version</Text>
        <Text>{window.appConfig.version}</Text>
      </Box>
    </Flex>
  </Flex>
);
