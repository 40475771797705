import { Box, Button, HStack, Text } from "@chakra-ui/react";
import React from "react";

interface UnverifiedBannerProps {
  onActivateVerification: () => void;
  isActivateVerificationLoading: boolean;
}

export const UnverifiedBanner = ({
  onActivateVerification,
  isActivateVerificationLoading,
}: UnverifiedBannerProps) => (
  <Box bg="#F2F2F2" pb={5} pos="fixed" left={0} top={0} right={0} zIndex={4}>
    <HStack py={3} bg="red.300" justifyContent="space-between" px={6}>
      <Text>
        You are UNVERIFIED. Until you are verified, you cannot obtain data from
        your clients.
      </Text>
      <Button
        onClick={onActivateVerification}
        isLoading={isActivateVerificationLoading}
        data-testid={"complete-verification-button"}
      >
        Complete Verification
      </Button>
    </HStack>
  </Box>
);
