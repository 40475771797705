import { AuthGuard } from "./auth/AuthGuard";
import { SidebarLayout } from "./layout/SidebarLayout";
import { FiHome, FiUsers, FiHelpCircle } from "react-icons/fi";
import { VscBook } from "react-icons/vsc";
import { FaRegBell } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { SidebarMenuLinkItem } from "./components/sidebar-menu/SidebarMenu";
import { VettingBannerContainer } from "./vetting/VettingBannerContainer";
import { PageFade } from "./App";
import { TermsToAcceptBanner } from "terms-ui";
import {
  useAcceptTermsMutation,
  useGetLatestTermsDetails,
} from "./company/termsQueryHooks";
import { useCompany } from "./company/useCompany";
import { Box, Button, Center } from "@chakra-ui/react";
import { format, isAfter } from "date-fns";
import { Company } from "./api/types/Company";
import { WizardSkeleton } from "wizard";
import { Result } from "acsiss-ui";
import { UnAuthorizedIcon } from "acsiss-ui/src/result/UnAuthorized";
import { useAuth } from "./auth/useAuth";
import { BETA_RELEASE_BANNER_HEIGHT, BetaBanner } from "acsiss-ui";
import { CgFileDocument } from "react-icons/cg";
import { BillingPastDueBannerContainer } from "./billing/BillingPastDueBannerContainer";
import { FullPageLoadingSpinner } from "./components/full-page-loading-spinner/FullPageLoadingSpinner";
import { WizardGuard } from "./wizards/WizardGuard";

const shouldShowTermsBanner = (company?: Company) => {
  if (!company) return false;

  if (company.hasAcceptedLatestTerms) return false;

  if (company.lastTermsAcceptedVersion === null) {
    return false;
  }

  // not expired
  return isAfter(new Date(company.termsMustBeAcceptedBy), new Date());
};

export const resolveShouldShowTermsWizard = (
  company?: Company,
  user?: User
) => {
  if (!company || !user) return false;

  if (user.role !== "Administrator") return false;

  if (company.hasAcceptedLatestTerms) return false;

  if (user.currentWizardName) return false;

  if (company.lastTermsAcceptedVersion === null) {
    return true;
  }

  // has expired
  return isAfter(new Date(), new Date(company.termsMustBeAcceptedBy));
};

const resolveShowAdminMustAcceptTermsByText = (
  company?: Company,
  user?: User
): string | undefined => {
  if (!user || !company) return undefined;

  if (company.hasAcceptedLatestTerms) return undefined;

  if (user.role === "Administrator") return undefined;

  return format(new Date(company.termsMustBeAcceptedBy), "dd-MMM-yy hh:mm a");
};

const resolveShowAdminMustAcceptTermsPage = (
  company?: Company,
  user?: User
) => {
  if (!user || !company) return false;

  if (company.hasAcceptedLatestTerms) return false;

  if (user.role === "Administrator") return false;

  // has expired
  return isAfter(new Date(), new Date(company.termsMustBeAcceptedBy));
};

interface AppRouteProps {
  children: JSX.Element;
}

const linkItems: SidebarMenuLinkItem[] = [
  { title: "Home", to: "/", icon: FiHome },
  { title: "Clients", to: "/customers", icon: FiUsers },
  { title: "Bank Statements", to: "/statements", icon: CgFileDocument },
  { title: "Notifications", to: "/notifications", icon: FaRegBell },
  { title: "Logs", to: "/logs", icon: VscBook },
  { title: "Help", to: "https://help.acsiss.com.au", icon: FiHelpCircle },
  {
    title: "Contact Us",
    to: "mailto:adviserhelp@acsiss.com.au",
    icon: AiOutlineMail,
  },
];

const BannerContainer = ({
  top,
  showAdminMustAcceptTermsBy,
}: {
  top: number;
  showAdminMustAcceptTermsBy?: string;
}) => {
  const { data } = useGetLatestTermsDetails();
  const { mutation, onMutate } = useAcceptTermsMutation();

  const handleAccept = (onSuccess: () => void) => {
    onMutate(data?.version ?? "", () => {
      mutation.reset();
      onSuccess();
    });
  };

  return (
    <TermsToAcceptBanner
      url={data?.url ?? ""}
      onAccept={handleAccept}
      isSaving={mutation.isLoading}
      top={top}
      showAdminMustAcceptTermsBy={showAdminMustAcceptTermsBy}
    />
  );
};

const Content = ({ children }: AppRouteProps) => {
  const { user, logout } = useAuth();
  const { company } = useCompany();
  const showAcceptTermsBanner = shouldShowTermsBanner(company);
  const showAcceptTermsWizard = resolveShouldShowTermsWizard(company, user);

  if (!user && !company) return <FullPageLoadingSpinner />;

  if (location.pathname.startsWith("/wizard")) {
    return children;
  }

  if (location.pathname === "/accept-terms") {
    return children;
  }

  // we have determined we need to show terms but have not
  // redirected there yet
  if (showAcceptTermsWizard) {
    return <WizardSkeleton />;
  }

  const shouldShowAdminMustAcceptTermsPage =
    resolveShowAdminMustAcceptTermsPage(company, user);

  if (shouldShowAdminMustAcceptTermsPage) {
    return (
      <Center mt={40} data-testid="admin-must-accept-terms-error">
        <Result
          icon={<UnAuthorizedIcon />}
          title="Terms and Conditions have not been accepted"
          subtitle="An administrator from your organisation must accept the terms and conditions before you can use this application."
          extra={
            <Box pt={6}>
              <Button colorScheme="blue" onClick={() => logout()}>
                Logout
              </Button>
            </Box>
          }
        />
      </Center>
    );
  }

  const showAdminMustAcceptTermsBy = resolveShowAdminMustAcceptTermsByText(
    company,
    user
  );

  const showBetaBanner = false;
  return (
    <>
      {showBetaBanner && <BetaBanner />}

      {showAcceptTermsBanner && (
        <Box bg="#F2F2F2" pb={2}>
          <BannerContainer
            top={showBetaBanner ? BETA_RELEASE_BANNER_HEIGHT : 0}
            showAdminMustAcceptTermsBy={showAdminMustAcceptTermsBy}
          />
        </Box>
      )}

      <VettingBannerContainer />
      <BillingPastDueBannerContainer />
      <SidebarLayout
        showAcceptTermsBanner={showAcceptTermsBanner}
        showBetaBanner={showBetaBanner}
        linkItems={linkItems}
      >
        <PageFade>{children}</PageFade>
      </SidebarLayout>
    </>
  );
};

export const AppRoute = ({ children }: AppRouteProps) => {
  return (
    <AuthGuard>
      <WizardGuard>
        <Content>{children}</Content>
      </WizardGuard>
    </AuthGuard>
  );
};
