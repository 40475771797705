import { useToast } from "acsiss-ui";
import { ApiError } from "../api/api";
import { Company, CompanyVetting } from "../api/types/Company";
import { useApiMutation } from "../api/useApi";
import { useCompany } from "./useCompany";

interface UpdateCompanyVettingsDto {
  Vettings: CompanyVetting[];
}

export const useUpdateCompanyVettings = () => {
  const { company, setCompany } = useCompany();
  const mutation = useApiMutation({
    // turn off default error toast
    onError: () => {},
  });
  const { errorToast } = useToast();
  const handleMutate = (
    input: UpdateCompanyVettingsDto,
    onSuccess?: () => void,
    onError?: (error: ApiError) => void
  ) => {
    mutation.mutate(
      {
        input,
        url: `/api/v1/company/${company?.id}/updatevettings`,
        method: "PUT",
      },
      {
        // @ts-ignore
        onSuccess: (data: { data: Company }) => {
          setCompany(data.data);
          if (onSuccess) {
            onSuccess();
          }
        },
        onError: (error: ApiError) => {
          if (onError) {
            onError(error);
          } else {
            errorToast({
              title: "Something went wrong",
              description: error.errors.join("\n"),
            });
          }
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
};

export const useClearCompanyVettings = () => {
  const { company, setCompany } = useCompany();
  const mutation = useApiMutation({
    // turn off default error toast
    onError: () => {},
  });
  const { errorToast } = useToast();
  const handleMutate = (
    input: {},
    onSuccess?: () => void,
    onError?: (error: ApiError) => void
  ) => {
    mutation.mutate(
      {
        input,
        url: `/api/v1/company/${company?.id}/clear-company-vettings`,
        method: "DELETE",
      },
      {
        // @ts-ignore
        onSuccess: (data: { data: Company }) => {
          console.log(company);
          setCompany(data.data);
          if (onSuccess) {
            onSuccess();
          }
        },
        onError: (error: ApiError) => {
          if (onError) {
            onError(error);
          } else {
            errorToast({
              title: "Something went wrong",
              description: error.errors.join("\n"),
            });
          }
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
};
