import { useState } from 'react';
import { PaginationState, SortingState } from '@tanstack/react-table';
import useDebounce from '../utils/useDebounce';
import { TablePaginationProps, TableSortingProps } from './DataTable';
import { PagedResult } from '../types/PagedResult';
import { makePaginationPropsFromQueryData } from './utils';

const DEFAULT_PAGINATION_STATE = {
  pageIndex: 0,
  pageSize: 20,
};

interface TableFilterProps<T extends object = object> {
  defaultPagination?: PaginationState;
  defaultSorting: [{ id: keyof T; desc: boolean }];
  manualSorting?: boolean;
}

export function useTableFilters<T extends object = object>({
  defaultSorting,
  defaultPagination,
  manualSorting = true,
}: TableFilterProps<T>) {
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 200);
  const [pagination, setPagination] = useState<PaginationState>(
    defaultPagination ?? DEFAULT_PAGINATION_STATE
  );

  // ts is complaining about id not being a string but it is :D
  // @ts-ignore
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);

  const sortingProps: TableSortingProps = {
    sorting,
    onChange: setSorting,
    manualSorting,
  };

  const makePaginatedProps = (
    result?: PagedResult<T>
  ): TablePaginationProps => {
    return makePaginationPropsFromQueryData(pagination, setPagination, result);
  };

  return {
    makePaginatedProps,
    sortingProps,
    sorting,
    onChangeSorting: setSorting,
    pagination,
    onChangePagination: setPagination,
    searchText,
    onChangeSearchText: (text: string) => {
      setPagination(DEFAULT_PAGINATION_STATE);
      setSearchText(text);
    },
    debouncedSearchText: debouncedSearchTerm,
  };
}
