import { useContext } from "react";
import { CustomerSearchContext } from "./CustomerSearchContext";
import { CustomerSearchContextType } from "./types";

export function useCustomerSearch() {
  const customerSearch = useContext<CustomerSearchContextType>(
    CustomerSearchContext
  );
  return customerSearch;
}
