﻿import { ApiError } from "../../api/api";
import { useApiMutation } from "../../api/useApi";
import { useToast } from "acsiss-ui";

export function useGenerateUpgradeToCompanyOnboardingSession() {
  const mutation = useApiMutation({
    // turn off default error toast
    onError: () => {},
  });
  const { errorToast } = useToast();
  const handleMutate = (
    onSuccess?: () => void,
    onError?: (error: ApiError) => void
  ) => {
    mutation.mutate(
      {
        url: `/api/v1/wizard-session/adviser-onboarding/generate/upgrade-to-company`,
        method: "POST",
      },
      {
        // @ts-ignore
        onSuccess: () => {
          if (onSuccess) {
            onSuccess();
          }
        },
        onError: (error: ApiError) => {
          if (onError) {
            onError(error);
          } else {
            errorToast({
              title: "Something went wrong",
              description: error.errors.join("\n"),
            });
          }
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
}
