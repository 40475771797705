import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
  Select,
} from "@chakra-ui/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { RequiredBadge } from "./RequiredBadge";

interface FormSelectProps extends UseFormRegisterReturn {
  errorMessage?: string;
  label: string;
  hint?: string;
  showIsRequired?: boolean;
  placeholder?: string;
  options: string[] | { value: string; label: string }[];
  isDisabled?: boolean;
}

export const FormSelect = React.forwardRef(
  (
    {
      errorMessage,
      label,
      hint,
      showIsRequired,
      options,
      ...rest
    }: FormSelectProps,
    ref: React.ForwardedRef<HTMLSelectElement>
  ) => {
    const isInvalid = Boolean(errorMessage);
    return (
      <FormControl isInvalid={isInvalid}>
        <HStack justifyContent="space-between">
          <FormLabel>{label}</FormLabel>
          {showIsRequired && <RequiredBadge />}
        </HStack>
        <Select bg="white" placeholder={hint ?? label} {...rest} ref={ref}>
          {options.map((o) => {
            if (typeof o === "object") {
              return (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              );
            }
            return (
              <option key={o} value={o}>
                {o}
              </option>
            );
          })}
        </Select>
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
    );
  }
);
