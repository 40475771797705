import { Container } from "@chakra-ui/react";
import React from "react";

interface WizardContainerProps {
  children: React.ReactNode;
}

export const WizardContainer = ({ children }: WizardContainerProps) => (
  <Container w="100vw" maxW="full" bg="gray.100" p={0}>
    <Container
      maxW="container.sm"
      display="flex"
      flexDirection="column"
      height="100vh"
      bg="white"
      p={{ base: 3, md: 8 }}
    >
      {children}
    </Container>
  </Container>
);
