import { Box, Button, HStack, Text } from "@chakra-ui/react";

interface BillingPastDueBannerProps {
  onManageCompany: () => void;
  onManageProfile: () => void;
  isIndividual: boolean;
}

export const BillingPastDueBanner = ({
  onManageCompany,
  onManageProfile,
  isIndividual,
}: BillingPastDueBannerProps) => (
  <Box bg="#F2F2F2" pb={5} pos="fixed" left={0} top={0} right={0} zIndex={4}>
    <HStack py={3} bg="red.300" justifyContent="space-between" px={6}>
      <Text>
        Billing Past Due. Until your subscription is paid, you cannot obtain
        data from your clients.
      </Text>
      {!isIndividual && (
        <Button onClick={onManageCompany}>Manage Company</Button>
      )}
      {isIndividual && (
        <Button onClick={onManageProfile}>Manage Profile</Button>
      )}
    </HStack>
  </Box>
);
