﻿import { FullPageLoadingSpinner } from "../components/full-page-loading-spinner/FullPageLoadingSpinner";
import { useAuth } from "../auth/useAuth";

type WizardGuardProps = {
  children?: React.ReactNode;
};

export function WizardGuard(props: WizardGuardProps) {
  const { user } = useAuth();

  if (
    !location.pathname.startsWith("/wizard") &&
    user?.currentWizardName &&
    user?.currentWizardSessionId
  ) {
    console.debug(`Redirecting to /wizard/${user.currentWizardName}`);
    window.location.href = `/wizard/${user.currentWizardName}`;
    return <FullPageLoadingSpinner />;
  }
  return <>{props.children}</>;
}
