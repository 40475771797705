import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import useReactRouterBreadcrumbs, {
  BreadcrumbData,
} from "use-react-router-breadcrumbs";
import {
  useGetCustomer,
  useGetCustomerAccount,
} from "../customers/customerQueryHooks";
import { Link, matchRoutes, useLocation } from "react-router-dom";
import { useGetExportLocation } from "../pages/export-locations/exportLocationQueries";

const CustomerBreadcrumb = ({ match }: any) => {
  const { data: customer } = useGetCustomer(match.params.customerId);
  if (customer) {
    return (
      <span>
        {customer?.firstName} {customer?.lastName}
      </span>
    );
  }
  return null;
};

const ExportLocationBreadcrumb = ({ match }: any) => {
  const { data } = useGetExportLocation(match.params.id);
  if (data) {
    return <span>{data?.name}</span>;
  }
  return null;
};

const CustomerAccountBreadCrumb = ({ match }: any) => {
  const { account } = useGetCustomerAccount(match.params);

  if (account) {
    return <span>{account.displayName}</span>;
  }
  return null;
};

const routes = [
  { path: "/", breadcrumb: "Dashboard" },
  {
    path: "/customers",
    breadcrumb: "Clients",
  },
  { path: "/customers/:customerId", breadcrumb: CustomerBreadcrumb },
  { path: "/connected-apps", breadcrumb: "Connected Apps" },
  { path: "/connected-apps/:id", breadcrumb: ExportLocationBreadcrumb },
  {
    path: "/customers/:customerId/account/:accountId",
    breadcrumb: CustomerAccountBreadCrumb,
  },
];

const useCurrentPath = (): string => {
  const location = useLocation();
  const result = matchRoutes(routes, location);

  if (result) {
    return result[0].route.path ?? location.pathname;
  }

  return location.pathname;
};

const makeResolvedBreadcrumbs = (
  currentPath: string,
  breadcrumbs: BreadcrumbData<string>[]
) => {
  if (currentPath === "/customers/:customerId/account/:accountId") {
    return breadcrumbs.filter(
      (b) => !b.key.endsWith("/account") && b.match.pathname !== "/"
    );
  }

  return breadcrumbs.length > 1
    ? breadcrumbs.filter((b) => b.match.pathname !== "/")
    : breadcrumbs;
};

export const Breadcrumbs = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(routes);
  const location = useLocation();
  const currentPath = useCurrentPath();

  const resolvedBreadcrumbs = makeResolvedBreadcrumbs(currentPath, breadcrumbs);

  return (
    <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
      {resolvedBreadcrumbs.map(({ match, breadcrumb }) => (
        <BreadcrumbItem key={match.pathname}>
          <BreadcrumbLink
            as={Link}
            color="blackAlpha.800"
            fontWeight={500}
            href={match.pathname}
            to={match.pathname}
            isCurrentPage={location.pathname === match.pathname}
          >
            {breadcrumb}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
