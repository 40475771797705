import { useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { Company } from "../api/types/Company";
import { CompanyContext } from "./CompanyContext";

export const CompanyProvider = ({ children }: { children: JSX.Element }) => {
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const {
    isOpen: isVettingModalOpen,
    onOpen: onOpenVettingModal,
    onClose: onCloseVettingModal,
  } = useDisclosure();
  return (
    <CompanyContext.Provider
      value={{
        isVettingModalOpen,
        onOpenVettingModal,
        onCloseVettingModal,
        company,
        setCompany,
      }}
    >
      <>{children}</>
    </CompanyContext.Provider>
  );
};
