﻿import { Center, Spinner } from "@chakra-ui/react";
import React from "react";

export const FullPageLoadingSpinner = () => {
  return (
    <Center h="100vh">
      <Spinner margin="auto" emptyColor="gray.200" color="blue.500" size="xl" />
    </Center>
  );
};
