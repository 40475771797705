import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
} from "@chakra-ui/react";
import { UseFormRegisterReturn } from "react-hook-form";

interface FormTextAreaProps extends UseFormRegisterReturn {
  errorMessage?: string;
  label: string;
  hint?: string;
  defaultValue?: string;
}

export const FormTextArea = React.forwardRef(
  (
    { errorMessage, label, hint, defaultValue, ...rest }: FormTextAreaProps,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ) => {
    const isInvalid = Boolean(errorMessage);
    return (
      <FormControl isInvalid={isInvalid} mb="16px">
        <FormLabel>{label}</FormLabel>
        <Textarea
          bg="white"
          minH={200}
          placeholder={hint ?? label}
          defaultValue={defaultValue}
          {...rest}
          ref={ref}
        />
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
    );
  }
);
