import React, { lazy, Suspense } from "react";
import { Fade } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./auth/AuthProvider";
import { ErrorBoundary } from "acsiss-ui";
import PageLazyLoader from "./components/lazy-loader/PageLazyLoader";
import { CompanyProvider } from "./company/CompanyProvider";
import { CustomerSearchProvider } from "./customers/customer-search-modal/CustomerSearchProvider";
import { useMsal } from "@azure/msal-react";
import { AppRoute } from "./AppRoute";

// The App.tsx component handles the routing of the application. It will decide, based on the url, which page
// to render.
//
// There are two types of routes: AuthenticatedRoutes and PublicRoutes.
// Each type has an array of routes, where each route is an array with two elements: the path and the component to render.
// The path is the url that will trigger the rendering of the component.
//
// The component is a lazy-loaded component that will be rendered when the path matches the url. The component refers to
// the file that contains the component to render.
// The file MUST export a default component, which takes ZERO props and returns JSX.

// Trying to access any of the AuthenticatedRoutes will redirect to the login page if the user is not authenticated.
const AuthenticatedRoutes = new Map([
  ["/", lazy(() => import("./pages/home/Home"))],
  [
    "/manage-company",
    lazy(() => import("./pages/manage-company/ManageCompanyPage")),
  ],
  ["/profile", lazy(() => import("./pages/profile/ProfilePage"))],
  ["/logs", lazy(() => import("./pages/log-notifications/LogsPage"))],
  [
    "/notifications",
    lazy(() => import("./pages/log-notifications/NotificationsPage")),
  ],
  [
    "/accept-terms",
    lazy(() => import("./pages/accept-terms/AcceptTermsWizardPage")),
  ],
  ["/customers", lazy(() => import("./pages/customers/CustomersPage"))],
  [
    "/connected-apps",
    lazy(() => import("./pages/export-locations/ExportLocationsPage")),
  ],
  [
    "/connected-apps/:id",
    lazy(() => import("./pages/export-locations/ExportLocationPage")),
  ],
  [
    "/customers/:customerId",
    lazy(() => import("./pages/customer/customer-page/CustomerPage")),
  ],
  [
    "/customers/:customerId/account/:accountId",
    lazy(() => import("./pages/customer-account/CustomerAccountPage")),
  ],
  [
    "/statements",
    lazy(() => import("./pages/bank-statements/BankStatementsPage")),
  ],
  [
    // This is the new onboarding page, used when the trusted advisor is signing up for the first time
    // This is not used by fintechs. There is a separate page for fintechs to onboard trusted advisors.
    // Fintechs use are the branded-onboarding wizard.
    "/wizard/adviser-onboarding",
    lazy(
      () =>
        import("./wizards/adviser-onboarding-wizard/AdviserOnboardingWizard")
    ),
  ],
  [
    // 2024-08-08: This is just here because we've changed the route of the onboarding wizard,
    // but we still need to support previous links that were generated
    "/wizard/unbranded-adviser-onboarding",
    lazy(
      () =>
        import("./wizards/adviser-onboarding-wizard/LegacyUnbrandedOnboarding")
    ),
  ],
]);

// PublicRoutes are routes that are accessible without authentication.

const PublicRoutes = new Map([
  [
    // When a MyData user invites an adviser to view their data, this is the route that
    // will be sent to the adviser.
    "/accept-invite",
    lazy(
      () =>
        import(
          "./wizards/adviser-onboarding-wizard/invite-from-customer-to-adviser/AcceptInvitePage"
        )
    ),
  ],
  [
    //This is the route the fintechs will redirect new adviser to, so that they can sign up
    "/flow/:id",
    lazy(
      () =>
        import(
          "./wizards/branded-adviser-onboarding/BrandedAdviserOnboardingLandingPage"
        )
    ),
  ],
  [
    // This is the route send via email to a company director.
    // This email is triggered when an advisor is setting up a company but can't complete the signup
    // because they need a company director to complete the GreenID step.
    "/invitation-to-company-director/:id/:userAcceptDecision",
    lazy(
      () =>
        import(
          "./wizards/director-adviser-onboarding/AcceptDirectorInviteLandingPage"
        )
    ),
  ],
  [
    // This is the old unbranded onboarding page, it is still here as there are a few steps that are
    // not yet in the new onboarding page. This page is used when a trusted advisor is signing up for the first time.
    // This is not used by fintechs.
    "/onboarding",
    lazy(
      () =>
        import(
          "./wizards/adviser-onboarding-wizard/OBSOLETE/AdvisorOnboardingPage"
        )
    ),
  ],
  [
    // This is the route that will be sent via email, asking a person to sign up as an adviser for a company.
    // The email is triggered by a company admin inviting a person to be an adviser for the company.
    "/company-invitation-to-user/:invitationId/:userAcceptDecision",
    lazy(
      () =>
        import("./pages/accept-company-user-invite/AcceptCompanyUserInvitePage")
    ),
  ],
  [
    "/invitation-to-mydata/:id/:userAcceptDecision",
    lazy(() => import("./pages/mydata/invite/MyDataInvitePage")),
  ],
  [
    "/greenid/verified",
    lazy(() => import("./pages/greenid-verified/GreenIdVerifiedPage")),
  ],
  [
    "/greenid/nonverified",
    lazy(() => import("./pages/greenid-nonverified/GreenIdNonverifiedPage")),
  ],
  [
    "/greenid/locked",
    lazy(() => import("./pages/greenid-locked/GreenIdLockedPage")),
  ],
  [
    "/onedrive-app-redirect",
    lazy(() => import("./pages/onedrive-app-redirect/OneDriveAppRedirectPage")),
  ],
  [
    "/support/update-detail/:vettingId",
    lazy(() => import("./pages/support/SupportUpdateRegistrationDetailsPage")),
  ],
]);

export const PageFade = ({ children }: { children: JSX.Element }) => (
  <Fade in={true}>{children}</Fade>
);

const PublicRoute = ({ children }: { children: JSX.Element }) => (
  <PageFade>{children}</PageFade>
);

export function App() {
  const msal = useMsal();

  const publicRoutes = Array.from(PublicRoutes).map(([path, component]) => ({
    path,
    component,
  }));

  const authenticatedRoutes = Array.from(AuthenticatedRoutes).map(
    ([path, component]) => ({
      path,
      component,
    })
  );

  return (
    <CompanyProvider>
      <AuthProvider>
        <CustomerSearchProvider>
          <ErrorBoundary onRetryLogin={() => msal.instance.loginRedirect()}>
            <Suspense fallback={<PageLazyLoader />}>
              <Routes>
                {authenticatedRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <AppRoute>
                        <route.component />
                      </AppRoute>
                    }
                  />
                ))}

                {publicRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <PublicRoute>
                        <route.component />
                      </PublicRoute>
                    }
                  ></Route>
                ))}
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </CustomerSearchProvider>
      </AuthProvider>
    </CompanyProvider>
  );
}
