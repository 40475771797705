import { useToast } from "acsiss-ui";
import { ApiError } from "../api/api";
import { Company, CompanyVetting } from "../api/types/Company";
import { useApiMutation } from "../api/useApi";
import { useAuth } from "../auth/useAuth";
import { useCompany } from "./useCompany";
import { useUpdateCompanyLogo } from "./useUpdateCompanyLogo";
import { useUpdateCompanyVettings } from "./useUpdateCompanyVettings";

export const useUpdateCompany = () => {
  const { user, onUserUpdated } = useAuth();
  const { company, setCompany } = useCompany();
  const { onMutate: updateLogoOnMutate } = useUpdateCompanyLogo();
  const { mutation: vettingsMutation, onMutate: updateVettingsOnMutate } =
    useUpdateCompanyVettings();
  const mutation = useApiMutation({
    // turn off default error toast
    onError: () => {},
  });
  const { errorToast } = useToast();
  const handleMutate = (
    // @todo convert to partial and use company from useCompany
    input: Company,
    onSuccess?: () => void,
    onError?: (error: ApiError) => void
  ) => {
    const isNewLogo = company?.companyLogo !== input.companyLogo;
    const newLogo = input.companyLogo;

    const areNewVettings = areThereNewVettings(
      company!.companyVettings,
      input.companyVettings
    );
    const newVettings = input.companyVettings;

    const errorToastOnError = (error: ApiError) => {
      errorToast({
        title: "Something went wrong",
        description: error.errors.join("\n"),
      });
    };

    mutation.mutate(
      {
        input: {
          ...input,
          companyLogo: company!.companyLogo,
          companyVettings: company!.companyVettings,
        },
        url: `/api/v1/company/${company?.id}`,
        method: "PUT",
      },
      {
        // @ts-ignore
        onSuccess: (data: { data: Company }) => {
          const hasUpgradedToCompanyPlan =
            company?.companyPlan.planType === "Individual" &&
            data.data.companyPlan.planType === "Company";

          if (hasUpgradedToCompanyPlan) {
            onUserUpdated({
              ...user,
              role: "Administrator",
            } as User);
          }

          // Note that we are assuming a user is never going to change the company logo and vettings at the same time
          if (isNewLogo) {
            handleImageUpdate(
              newLogo ?? "",
              updateLogoOnMutate,
              onError ?? errorToastOnError
            );
          } else if (areNewVettings) {
            handleVettingsUpdate(
              newVettings ?? [],
              updateVettingsOnMutate,
              onSuccess!,
              onError ?? errorToastOnError
            );
          } else {
            setCompany(data.data);
            if (onSuccess) {
              onSuccess();
            }
          }
        },
        onError: (error: ApiError) => {
          if (onError) {
            onError(error);
          } else {
            errorToastOnError(error);
          }
        },
      }
    );
  };

  return {
    mutation,
    onMutate: handleMutate,
    isLoading: mutation.isLoading || vettingsMutation.isLoading,
  };
};

function handleImageUpdate(
  newLogo: string,
  updateLogoOnMutate: any,
  onError: (error: ApiError) => void
) {
  updateLogoOnMutate(
    {
      LogoBase64: newLogo,
    },
    () => {},
    (error: ApiError) => onError(error)
  );
}

function handleVettingsUpdate(
  vettings: CompanyVetting[],
  updateVettingsOnMutate: any,
  onSuccess: () => void,
  onError: (error: ApiError) => void
) {
  updateVettingsOnMutate(
    {
      Vettings: vettings,
    },
    onSuccess,
    (error: ApiError) => onError(error)
  );
}

function areThereNewVettings(
  existingVettings: CompanyVetting[],
  newVettings: CompanyVetting[]
) {
  const omitDateModified = (arr: CompanyVetting[]) =>
    arr.map(({ dateModified, ...rest }) => rest);

  const companyVettingsWithoutDate = omitDateModified(existingVettings || []);
  const inputVettingsWithoutDate = omitDateModified(newVettings);

  return (
    JSON.stringify(companyVettingsWithoutDate) !==
    JSON.stringify(inputVettingsWithoutDate)
  );
}
