import React from "react";
import { WizardProps } from "react-use-wizard/dist/types";
import { WizardContainer } from "./WizardContainer";
import { WizardStepSkeleton } from "./WizardStepSkeleton";
import { WizardWithFooterStateProvider } from "./WizardWithFooterStateProvider";

interface WizardSkeletonProps extends WizardProps {}

export const WizardSkeleton = (props: WizardSkeletonProps) => (
  <WizardContainer>
    <WizardWithFooterStateProvider {...props}>
      <WizardStepSkeleton />
    </WizardWithFooterStateProvider>
  </WizardContainer>
);
