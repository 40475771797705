import React from "react";
import {
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  VStack,
} from "@chakra-ui/react";

interface WizardStepSkeletonProps {}

export const WizardStepSkeleton = (props: WizardStepSkeletonProps) => (
  <VStack spacing={7} mb={3}>
    <SkeletonText noOfLines={1} w="70%" skeletonHeight={6} />
    <SkeletonCircle opacity={0.3} height="85px" w="85px" />
    <SkeletonText w="100%" noOfLines={4} spacing={3} skeletonHeight="2" />
  </VStack>
);
