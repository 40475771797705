import { Text } from "@chakra-ui/react";
import { PaginationState, SortingState } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../utils/useDebounce";
import { useGetCustomers } from "../customerQueryHooks";
import { Customer } from "../types";
import { useCustomerSearch } from "./useCustomerSearch";
import EntitySearchModal from "entity-search";

export const CustomerSearchContainer = () => {
  const [hasOpened, setHasOpened] = useState(false);
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useCustomerSearch();
  const [searchText, setSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(searchText, 200);

  const pagination: PaginationState = {
    pageIndex: 0,
    pageSize: 20,
  };
  const [sorting] = useState<SortingState>([]);

  const { data } = useGetCustomers(
    {
      pagination,
      searchText: debouncedSearchTerm,
      sorting,
    },
    {
      enabled: hasOpened,
    }
  );
  const customers: Customer[] = data?.data ?? [];

  const handleOpenCustomer = (c: Customer) => {
    navigate(`/customers/${c.id}`);
  };

  useEffect(() => {
    if (isOpen) {
      setHasOpened(true);
    }
  }, [isOpen]);

  return (
    <>
      <EntitySearchModal<Customer>
        placeholder="Search for a client to open"
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        items={customers}
        onSelect={handleOpenCustomer}
        searchText={searchText}
        onSearchTextChange={setSearchText}
        renderDescription={(i) => (
          <Text>
            {i.firstName} {i.lastName}
          </Text>
        )}
      />
    </>
  );
};
