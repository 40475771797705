import {
  ComponentStyleConfig,
  extendTheme,
  theme as base,
  ThemeConfig,
} from "@chakra-ui/react";
import { Dict } from "@chakra-ui/utils";
import { withProse } from "@nikolovlazar/chakra-ui-prose";

import "@fontsource-variable/public-sans";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const fonts = {
  heading: `Raleway, ${base.fonts.heading}`,
};

// handy website for generating palettes
// https://smart-swatch.netlify.app/#00ace6
const trustedAdvisorColors = {
  brand: {
    500: "#F9A526",
  },
  rowHighlight: "#FEEBC8",
};

const myDataColors = {
  brand: {
    500: "#5cbf71",
  },
  rowHighlight: "#BEE3F8",
};

const cdrReadyColors = {
  brand: {
    darkBlue: "#002760",
    blue: "#0d45d6",
    cdrGreen: "#1fc296",
    darkerGreen: "#179c78",
    primaryGreen: "#5cbf71",
    grey: "#667085",
    darkerGrey: "#344054",
    gold: "#ecb003",
  },
};

const Heading: ComponentStyleConfig = {
  baseStyle: {
    color: "brand.500",
    fontFamily: "Raleway",
  },
};

const components = {
  Heading,
};

const baseTheme = extendTheme(
  {
    config,
    fonts,
    colors: trustedAdvisorColors,
    components,
  },
  withProse({
    baseStyle: {
      h1: {
        fontSize: "lg",
      },
    },
  })
);

export const myDataTheme: Dict = extendTheme({
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    ...myDataColors,
  },
});

export const trustedAdvisorTheme: Dict = extendTheme({
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    ...trustedAdvisorColors,
  },
});

export const cdrReadyTheme: Dict = extendTheme({
  fonts: {
    heading: `'Public Sans', sans-serif`,
    body: `'Public Sans', sans-serif`,
  },
  colors: {
    ...cdrReadyColors,
  },
  components: {
    Button: {
      variants: {
        "positive-case": {
          bg: "brand.cdrGreen",
          _hover: { bg: "brand.darkerGreen" },
          _disabled: {
            bg: "brand.cdrGreen",
            pointerEvents: "none",
          },
          color: "white",
        },
      },
    },
  },
});
