import { MenuItem } from "@chakra-ui/react";
import React from "react";
import { RiListSettingsFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

export const ManageCompanyContainer = () => {
  const navigate = useNavigate();
  return (
    <MenuItem
      as="button"
      onClick={() => {
        navigate("/manage-company");
      }}
      icon={<RiListSettingsFill />}
      data-testid="user-menu-manage-company-button"
    >
      Manage company
    </MenuItem>
  );
};
