import {
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaBell } from "react-icons/fa";
import { NotificationMenuItem } from "./NotificationMenuItem";
import { Notification } from "./types";
import { useNavigate } from "react-router-dom";
import { IoMailOpenOutline } from "react-icons/io5";
import { EyeIcon } from "../icons/EyeIcon";

interface NotificationsMenuProps {
  notifications: Notification[];
  unreadCount: string | number;
  onMarkRead: (id: string) => void;
  onMarkAllRead: () => void;
}

export const NotificationsMenu = ({
  notifications,
  unreadCount,
  onMarkRead,
  onMarkAllRead,
}: NotificationsMenuProps) => {
  const navigate = useNavigate();

  const [iconColor, setIconColor] = useState("darkblue");

  function selectViewAllNotifications(onClose: any) {
    onClose();
    navigate("/notifications");
  }

  return (
    <Menu closeOnSelect={false}>
      {({ onClose }) => (
        <>
          <MenuButton
            variant="ghost"
            as={IconButton}
            aria-label="notifications"
            icon={
              <Box>
                {Number(String(unreadCount).replace("+", "")) > 0 && (
                  <Box position="absolute" right={"0px"} top={"-2px"}>
                    <Circle style={{ backgroundColor: "#5DD068" }} size={6}>
                      <Text fontSize="xs">{unreadCount}</Text>
                    </Circle>
                  </Box>
                )}
                <FaBell size={22} />
              </Box>
            }
          />
          <MenuList
            shadow="14px 17px 40px 4px rgba(112, 144, 176, 0.18)"
            zIndex={10}
            bg="white"
            mt="12px"
            minW="520px"
            maxW={{ base: "360px", md: "500px" }}
          >
            <Flex direction="column" mx="16px" my="1">
              <Flex justifyContent="space-between">
                <HStack spacing={0}>
                  <Text fontSize="2xl" fontWeight="bold">
                    Your Notifications
                  </Text>
                  <Tooltip
                    label={"View All Notifications"}
                    hasArrow
                    gutter={0}
                    placement={"top"}
                  >
                    <IconButton
                      _hover={{ color: "gray" }}
                      color="black"
                      variant={"ghost"}
                      aria-label={"View All"}
                      icon={<EyeIcon />}
                      data-testid="view-all-notifications-icon"
                      onClick={() => selectViewAllNotifications(onClose)}
                      size={"lg"}
                      zIndex={11}
                    />
                  </Tooltip>
                </HStack>
                <Button
                  leftIcon={<IoMailOpenOutline color={iconColor} />}
                  colorScheme="blue"
                  variant="ghost"
                  onMouseEnter={() => setIconColor("lightblue")}
                  onMouseLeave={() => setIconColor("darkblue")}
                  _hover={{ bg: "transparent", color: "lightblue" }}
                  onClick={onMarkAllRead}
                  aria-label="Mark all notifications as read"
                  fontSize="md"
                >
                  Mark All As Read
                </Button>
              </Flex>
            </Flex>
            <Divider />
            {notifications.length ? (
              <>
                {notifications.map((item) => (
                  <NotificationMenuItem
                    key={item.id}
                    {...item}
                    onMarkRead={() => onMarkRead(item.id)}
                  />
                ))}
              </>
            ) : (
              <Text py={4} px="3" fontSize="lg">
                You have no notifications
              </Text>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
