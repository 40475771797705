import React, { memo } from "react";
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  BoxProps,
  chakra,
} from "@chakra-ui/react";
import { flexRender, Row, Table as TableType } from "@tanstack/react-table";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";

declare module "@tanstack/react-table" {
  // @ts-ignore
  interface ColumnMeta {
    isRightAligned?: boolean;
  }
}
export interface TableWrapperProps {
  overflowX?: BoxProps["overflowX"];
  maxH?: BoxProps["maxH"];
  borderWidth?: BoxProps["borderWidth"];
}

interface TableProps<T> {
  instance: TableType<any>;
  wrapperProps?: TableWrapperProps;
  onRowClick?: (data: T) => void;
  checkRowIsDisabled?: (data: T) => boolean;
  checkRowIsSelected?: (data: T) => boolean;
  testId?: string;
}

interface TableRowProps<T> {
  row: Row<T>;
  onRowClick?: (data: T) => void;
  checkRowIsDisabled?: (data: T) => boolean;
  checkRowIsSelected?: (data: T) => boolean;
  testId?: string;
}

function TableRow<T>({
  row,
  onRowClick,
  checkRowIsDisabled,
  checkRowIsSelected,
}: TableRowProps<T>) {

  const isSelected = checkRowIsSelected
    ? checkRowIsSelected(row.original)
    : undefined;
  const isDisabled = checkRowIsDisabled
    ? checkRowIsDisabled(row.original)
    : undefined;
  const canClickRow = onRowClick && !isDisabled;

  return (
    <Tr
      key={row.id}
      borderColor="#DBEAF1"
      bg={isSelected ? "rowHighlight" : undefined}
      cursor={canClickRow ? "pointer" : undefined}
      _hover={
        canClickRow
          ? {
              bg: "rowHighlight",
            }
          : undefined
      }
      onClick={
        !canClickRow
          ? undefined
          : () => {
              onRowClick(row.original);
            }
      }
    >
      {row.getVisibleCells().map((cell) => (
        <Td
          key={cell.id}
          textAlign={
            cell.column.columnDef?.meta?.isRightAligned ? "right" : undefined
          }
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Td>
      ))}
    </Tr>
  );
}

// @todo add memo back in
const MemoizedTableRow = React.memo(TableRow) as typeof TableRow;
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-656596623

export function Table<T>({
  instance,
  wrapperProps,
  onRowClick,
  checkRowIsDisabled,
  checkRowIsSelected,
  testId,
}: TableProps<T>) {
  const headBg = "white";

  const defaultOverflowX: BoxProps["overflowX"] = "auto";

  return (
    <>
      <Box
        overflowX={wrapperProps?.overflowX || defaultOverflowX}
        maxH={wrapperProps?.maxH}
        borderWidth={wrapperProps?.borderWidth || "1px"}
        borderRadius="base"
        mb={4}
        minH={instance.getRowModel().rows.length !== 0 ? "100px" : ""}
      >
        <ChakraTable bg="white" fontSize={"md"} data-testid={testId}>
          <Thead>
            {instance.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    userSelect="none"
                    position="sticky"
                    zIndex={1}
                    top={0}
                    bg={headBg}
                    fontFamily="Raleway"
                    fontWeight={600}
                    fontSize={14}
                    color="#C2C2C2"
                    textTransform="unset"
                    textAlign={
                      header.column.columnDef?.meta?.isRightAligned
                        ? "right"
                        : undefined
                    }
                  >
                    {header.isPlaceholder ? null : (
                      <Box
                        display="inline-flex"
                        flexDir={
                          header.column.columnDef?.meta?.isRightAligned
                            ? "row-reverse"
                            : "row"
                        }
                        cursor={
                          header.column.getCanSort() ? "pointer" : undefined
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}

                        <chakra.span
                          pl={
                            header.column.columnDef?.meta?.isRightAligned
                              ? 0
                              : 3
                          }
                          pr={
                            header.column.columnDef?.meta?.isRightAligned
                              ? 3
                              : 0
                          }
                        >
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === "desc" ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Box>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {instance.getRowModel().rows.map((row) => (
              <MemoizedTableRow<T>
                key={row.id}
                row={row}
                onRowClick={onRowClick}
                checkRowIsDisabled={checkRowIsDisabled}
                checkRowIsSelected={checkRowIsSelected}
              />
            ))}
          </Tbody>
        </ChakraTable>
      </Box>
    </>
  );
}
