import React from "react";
import { Button, MenuItem, useDisclosure } from "@chakra-ui/react";
import { RiListSettingsFill } from "react-icons/ri";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { useUpdateCompany } from "../../company/useUpdateCompany";
import { useCompany } from "../../company/useCompany";
import { CompanyPlanType } from "../../api/types/Company";

interface UpgradeToCompanyContainerProps {}

export const UpgradeToCompanyContainer = (
  props: UpgradeToCompanyContainerProps
) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const cancelRef = React.useRef<any>();
  const { mutation, onMutate } = useUpdateCompany();
  const { company } = useCompany();

  return (
    <>
      <MenuItem as="button" onClick={onOpen} icon={<RiListSettingsFill />}>
        Upgrade to company
      </MenuItem>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef!}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Upgrade to company?
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isLoading={mutation.isLoading}
                colorScheme="blue"
                data-testid={"upgrade-to-company-confirmation"}
                onClick={() => {
                  onMutate(
                    {
                      ...company!,
                      companyPlan: {
                        ...company!.companyPlan,
                        planType: "Company" as CompanyPlanType,
                      },
                    },
                    () => {
                      // (reset user role)
                      window.location.href = "/manage-company";
                    }
                  );
                }}
                ml={3}
              >
                Upgrade to Company
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
