import React from "react";
import { SearchIcon } from "@chakra-ui/icons";
import { chakra, HStack, Kbd, Text, VisuallyHidden } from "@chakra-ui/react";

interface CustomerSearchButtonProps {
  onOpen: () => void;
}

const ACTION_KEY_DEFAULT = ["Ctrl", "Control"];
const ACTION_KEY_APPLE = ["⌘", "Command"];

export const CustomerSearchButton = ({ onOpen }: CustomerSearchButtonProps) => {
  const [actionKey, setActionKey] = React.useState<string[]>(ACTION_KEY_APPLE);
  React.useEffect(() => {
    if (typeof navigator === "undefined") return;
    const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    if (!isMac) {
      setActionKey(ACTION_KEY_DEFAULT);
    }
  }, []);

  return (
    <chakra.button
      flex="1"
      type="button"
      role="search"
      lineHeight="1.2"
      w="100%"
      bg={"white"}
      whiteSpace="nowrap"
      display={"flex"}
      alignItems="center"
      color="gray.400"
      py="2"
      px="3"
      outline="0"
      _focus={{ shadow: "outline" }}
      // shadow="base"
      borderRadius="30px"
      aria-label="Jump to client"
      onClick={onOpen}
      borderWidth={"1px"}
      borderColor="gray.100"
    >
      <SearchIcon />

      <HStack
        w="full"
        ml="3"
        spacing="4px"
        display={{ base: "none", md: "flex" }}
      >
        <Text textAlign="left" flex="1">
          Jump to client..
        </Text>
        <HStack spacing="4px">
          <VisuallyHidden>Press </VisuallyHidden>
          <Kbd color="gray.500" rounded="2px">
            <chakra.div
              as="abbr"
              title={actionKey[1]}
              textDecoration="none !important"
            >
              {actionKey[0]}
            </chakra.div>
          </Kbd>
          <VisuallyHidden> and </VisuallyHidden>
          <Kbd color="gray.500" rounded="2px">
            K
          </Kbd>
          <VisuallyHidden> to search</VisuallyHidden>
        </HStack>
      </HStack>
    </chakra.button>
  );
};
