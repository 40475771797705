import React, { useCallback } from "react";
import type { FC } from "react";
import {
  Avatar,
  Box,
  Button,
  Icon,
  SkeletonCircle,
  Text,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  MenuDivider,
} from "@chakra-ui/react";
import { FaChevronDown, FaSignOutAlt, FaUser } from "react-icons/fa";
import { useAuth } from "../../auth/useAuth";
import { EyeIcon } from "../icons/EyeIcon";
import { useCompany } from "../../company/useCompany";
import { UpgradeToCompanyContainer } from "./UpgradeToCompanyContainer";
import { ManageCompanyContainer } from "./ManageCompanyContainer";
import { useNavigate } from "react-router-dom";
import { TrustedAdvisorLogo } from "../logo/TrustedAdvisorLogo";

const UserMenuLoader: FC = () => (
  <Button
    size="sm"
    variant="ghost"
    px={1}
    rightIcon={<Icon pr={1} as={FaChevronDown} />}
  >
    <SkeletonCircle size="8" />
  </Button>
);

const UserMenuButton: FC<{ user: User }> = ({ user }) => {
  return (
    <MenuButton
      data-testid="user-menu-button"
      as={Button}
      size="sm"
      variant="none"
      px={1}
      rightIcon={<Icon pr={1} as={FaChevronDown} />}
    >
      <Avatar size="sm" name={`${user?.firstName} ${user.lastName}`} />
    </MenuButton>
  );
};

export const UserMenu = () => {
  const { user, logout, canManageCompany } = useAuth();
  const { onOpenVettingModal, company } = useCompany();
  const navigate = useNavigate();

  const handleOpenProfile = useCallback(() => {
    navigate("/profile");
  }, []);

  return (
    <Menu>
      {user ? <UserMenuButton user={user} /> : <UserMenuLoader />}

      <MenuList zIndex={999999} p="20px">
        <MenuItem cursor="unset" bg={{ color: "white" }}>
          <Box w="200px">
            <TrustedAdvisorLogo />
          </Box>
        </MenuItem>
        <MenuDivider />
        <MenuItem>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row">
              <Text fontWeight={600}>
                {`Hi ${user?.firstName} ${user?.lastName} 👋`}
              </Text>
            </Box>
            <Text fontSize={14} fontWeight={400}>
              {user?.email}
            </Text>
          </Box>
        </MenuItem>
        <MenuItem
          as="button"
          onClick={handleOpenProfile}
          icon={<FaUser />}
          data-testid={"user-menu-profile-button"}
        >
          Profile
        </MenuItem>
        {Boolean(company && company.companyPlan.planType === "Individual") && (
          <UpgradeToCompanyContainer />
        )}
        {Boolean(
          canManageCompany &&
            company &&
            company.companyPlan.planType === "Company"
        ) && <ManageCompanyContainer />}
        <MenuItem as="button" onClick={onOpenVettingModal} icon={<EyeIcon />}>
          View Verification
        </MenuItem>
        <MenuItem as="button" onClick={() => logout()} icon={<FaSignOutAlt />}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
