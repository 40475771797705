import {
  Box,
  Button,
  Icon,
  Square,
  Text,
  useDisclosure,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FiInfo } from "react-icons/fi";
import { TermsContainer } from "./TermsContainer";

interface TermsToAcceptBannerProps {
  url: string;
  onAccept: (onSuccess: () => void) => void;
  isSaving: boolean;
  top: number;
  showAdminMustAcceptTermsBy?: string;
}

export const TERMS_TO_ACCEPT_BANNER_HEIGHT = 80;

export const TermsToAcceptBanner = ({
  url,
  onAccept,
  isSaving,
  top,
  showAdminMustAcceptTermsBy,
}: TermsToAcceptBannerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const height = `${TERMS_TO_ACCEPT_BANNER_HEIGHT}px`;
  const showAcceptTermsBanner = true;

  return (
    <>
      {showAcceptTermsBanner && (
        <Box as="section">
          <Box
            boxShadow="sm"
            bg="white"
            position="fixed"
            zIndex={1}
            top={`${top}px`}
            right={0}
            left={0}
            display="flex"
            height={height}
            w="full"
            alignItems="center"
            px={4}
          >
            <Box flex={1} display="flex" alignItems="center">
              <Square mr={2} size="12" bg="gray.100" borderRadius="md">
                <Icon as={FiInfo} boxSize="6" />
              </Square>
              {showAdminMustAcceptTermsBy ? (
                <Box
                  display="flex"
                  flexDir={{
                    base: "column",
                    md: "row",
                  }}
                  flex={1}
                >
                  <Text fontSize="sm" flex={1}>
                    New terms and conditions are available.
                  </Text>
                  <Text
                    data-testid="terms-to-accept-banner-admin-must-accept-text"
                    fontSize="sm"
                    fontWeight="bold"
                  >
                    An administrator must accept these terms by{" "}
                    {showAdminMustAcceptTermsBy}.
                  </Text>
                </Box>
              ) : (
                <Text>New terms and conditions are available.</Text>
              )}
            </Box>
            {!showAdminMustAcceptTermsBy && (
              <Box>
                <Button
                  data-testid="accept-terms-banner-view-terms"
                  onClick={onOpen}
                  colorScheme="blue"
                  width="full"
                >
                  View Updated Terms and Conditions
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}

      <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW="80%" minH="80%">
          <ModalHeader fontSize={24} alignSelf="center">
            Terms and Conditions
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} px={6}>
            <TermsContainer url={url} />
          </ModalBody>

          <ModalFooter justifyContent="space-between">
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="green"
              onClick={() => onAccept(onClose)}
              isLoading={isSaving}
              data-testid="accept-terms-modal-accept-button"
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
