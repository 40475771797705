import { OnChangeFn, PaginationState } from "@tanstack/react-table";
import { PagedResult } from "../types/PagedResult";
import { TablePaginationProps } from "./DataTable";

export const makePaginationPropsFromQueryData = (
  pagination: PaginationState,
  onChange: OnChangeFn<PaginationState>,
  result?: PagedResult<any>
): TablePaginationProps => {
  const totalCount = result?.totalCount ?? 0;
  const pageCount = result?.totalPages ?? 0;

  return {
    pagination,
    onChange,
    pageCount,
    totalCount,
  };
};

export function CalculateNewBankLogoTableIconHeight(
  defaultHeight: number,
  heightOverridePercentage?: number
): number {
  if (!heightOverridePercentage) {
    return defaultHeight;
  }
  if (heightOverridePercentage < 25 || heightOverridePercentage > 500) {
    return defaultHeight;
  }

  return defaultHeight * (heightOverridePercentage / 100);
}
