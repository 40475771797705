import { Box } from "@chakra-ui/react";
import React from "react";
import {
  SidebarMenu,
  SidebarMenuLinkItem,
  resolveTop,
} from "../components/sidebar-menu/SidebarMenu";
import { LayoutFooter } from "./LayoutFooter";
import { LayoutTopBar } from "./LayoutTopBar";

export const SidebarLayout = ({
  children,
  linkItems,
  showAcceptTermsBanner,
  showBetaBanner,
}: {
  children: JSX.Element;
  linkItems: SidebarMenuLinkItem[];
  showAcceptTermsBanner: boolean;
  showBetaBanner: boolean;
}) => {
  const top = resolveTop({
    showBetaBanner,
    showAcceptTermsBanner,
  });
  return (
    <Box bg="#F2F2F2">
      <Box display="flex" minH="100vh" bg="#F2F2F2">
        <SidebarMenu
          showAcceptTermsBanner={showAcceptTermsBanner}
          showBetaBanner={showBetaBanner}
          linkItems={linkItems}
        />
        <Box
          mt={top}
          pl={{
            base: "8px",
            md: "50px",
          }}
          pr={{
            base: "8px",
            md: 5,
          }}
          flex={1}
          w="full"
          id="main-content"
          overflowX="hidden"
        >
          <LayoutTopBar linkItems={linkItems} />
          <Box pt={3} px={0}>
            {children}
          </Box>
        </Box>
      </Box>
      <LayoutFooter version={window.appConfig.version} />
    </Box>
  );
};
