import {
  MutationFunction,
  QueryKey,
  QueryFunction,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import api, { ApiError } from "./api";
import { useToast } from "../../../../libs/shared-ui/src/main";
import { useAuth } from "../auth/useAuth";

interface ApiGetRequest<TData> extends ApiRequest {
  options: UseQueryOptions<TData, ApiError>;
}

export const useApiGet = <TData>(request: ApiGetRequest<TData>) => {
  const { getToken } = useAuth();
  const { errorToast } = useToast();

  const handleFetch: QueryFunction<TData, QueryKey> = async () => {
    let token;
    if (request.useToken != false) {
      token = await getToken();
    }
    return api.get(request.url, token, request.headers);
  };

  const defaultErrorToast = (error: ApiError) => {
    errorToast({
      title: "Something went wrong when requesting data",
      description: error.errors.join("\n"),
    });
  };

  request.options.queryFn = handleFetch;
  request.options.onError ??= defaultErrorToast;
  return useQuery(request.options);
};

// Used when the request to retrieve the data may have side effects
export const useApiPostRetrieveGet = <TData>(request: ApiGetRequest<TData>) => {
  const { getToken } = useAuth();
  const { errorToast } = useToast();

  const handleFetch: QueryFunction<TData, QueryKey> = async () => {
    let token;
    if (request.useToken != false) {
      token = await getToken();
    }
    return api.post(request.url, {}, token, request.headers, true);
  };

  const defaultErrorToast = (error: ApiError) => {
    errorToast({
      title: "Something went wrong when requesting data",
      description: error.errors.join("\n"),
    });
  };

  request.options.queryFn = handleFetch;
  request.options.onError ??= defaultErrorToast;
  return useQuery(request.options);
};

interface MutationInput extends ApiRequest {
  input?: object;
  method?: "POST" | "PUT" | "DELETE";
}

export const useApiMutation = <TData, TError, TVariables extends MutationInput>(
  options?: UseMutationOptions
) => {
  const { getToken } = useAuth();
  const { errorToast } = useToast();

  const onError = (
    error: ApiError,
    variables: TVariables,
    context: unknown
  ) => {
    if (options?.onError) {
      // @ts-ignore
      options.onError(error, variables, context);
    } else {
      errorToast({
        title: "Something went wrong",
        description: error.errors.join("\n"),
      });
    }
  };

  const mutationFn: MutationFunction<TData, TVariables> = async ({
    url,
    input = {},
    method = "POST",
    useToken = true,
    headers = new Headers(),
  }: MutationInput) => {
    let token;
    if (useToken) {
      token = await getToken();
    }

    if (method === "POST") {
      return api.post(url, input, token, headers);
    }
    if (method === "DELETE") {
      return api.del(url, token, headers);
    }
    return api.put(url, input, token, headers);
  };

  // @ts-ignore
  return useMutation(mutationFn, {
    ...options,
    onError,
  });
};
