import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const EyeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 16 12" {...props}>
    <path
      d="M10.1327 5.8668C10.1327 7.04504 9.1776 8.00019 7.99936 8.00019C6.82112 8.00019 5.86597 7.04504 5.86597 5.8668C5.86597 4.68856 6.82112 3.73341 7.99936 3.73341C9.1776 3.73341 10.1327 4.68856 10.1327 5.8668Z"
      fill="currentColor"
    />

    <path
      d="M15.9682 5.68567C14.686 2.36915 11.553 0.133872 7.99977 0.000411987C4.44669 0.134052 1.31395 2.3693 0.0317775 5.68567C-0.0105925 5.80285 -0.0105925 5.93117 0.0317775 6.04835C1.3138 9.36491 4.44657 11.6004 7.99977 11.7341C11.553 11.6006 14.686 9.36534 15.9682 6.04882C16.0106 5.93164 16.0106 5.80333 15.9682 5.68615V5.68567ZM7.99976 9.33425C6.08499 9.33425 4.53276 7.78202 4.53276 5.86725C4.53276 3.95248 6.08499 2.40025 7.99976 2.40025C9.91453 2.40025 11.4668 3.95248 11.4668 5.86725C11.4639 7.78083 9.91334 9.33138 7.99976 9.33425Z"
      fill="currentColor"
    />
  </Icon>
);
