import React, { useCallback, useEffect } from "react";
import { useCompany } from "../company/useCompany";
import { UnverifiedBanner } from "./UnverifiedBanner";
import { VerificationModal } from "./VerificationModal";
import { useNavigate } from "react-router-dom";
import { useGenerateUpgradeToCompanyOnboardingSession } from "./hooks/useGenerateOnboardingSession";
import { useAuth } from "../auth/useAuth";

export const VettingBannerContainer = () => {
  const navigate = useNavigate();

  const { company, isVettingModalOpen, onCloseVettingModal } = useCompany();
  const { user, loadUser } = useAuth();

  const { mutation, onMutate: generateUpgradeToCompanySession } =
    useGenerateUpgradeToCompanyOnboardingSession();

  const showIncompleteVerificationBanner =
    company && company.companyVettingStatus !== "Green";

  function generateSessionAndRedirect() {
    // Don't bother generating a user session if the user already has an associated active session
    if (Boolean(user?.currentWizardSessionId)) {
      location.reload();
    }
    generateUpgradeToCompanySession(() => {
      location.reload();
    });
  }

  return (
    <>
      {showIncompleteVerificationBanner && (
        <UnverifiedBanner
          onActivateVerification={generateSessionAndRedirect}
          isActivateVerificationLoading={mutation.isLoading}
        />
      )}
      {company && (
        <VerificationModal
          isOpen={isVettingModalOpen}
          company={company}
          onClose={onCloseVettingModal}
          unverifiedRedirect={generateSessionAndRedirect}
          isSaving={mutation.isLoading}
        />
      )}
    </>
  );
};
