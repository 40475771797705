import {
  Box,
  CloseButton,
  Divider,
  HStack,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { formatDistanceToNow } from "date-fns";
import React from "react";
import { Notification } from "./types";

interface NotificationMenuItemProps extends Notification {
  onMarkRead: (id: string) => void;
}

export const NotificationMenuItem = ({
  id,
  dateCreated,
  message,
  onMarkRead,
  hasBeenRead,
}: NotificationMenuItemProps) => (
  <>
    <MenuItem as="div" bg={hasBeenRead ? undefined : "blue.50"}>
      <HStack w="full">
        <Box w="full">
          <Box>
            <Text size="sm">{message}</Text>
          </Box>
          <Box>
            <Text fontSize="xs">
              {formatDistanceToNow(new Date(dateCreated), {
                addSuffix: true,
              })}
            </Text>
          </Box>
        </Box>
        <CloseButton
          variant="ghost"
          size="sm"
          aria-label="mark notification as read"
          color="red"
          onClick={(_) => onMarkRead(id)}
        />
      </HStack>
    </MenuItem>
    <Divider />
  </>
);
