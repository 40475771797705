import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Alert, AlertIcon, SkeletonText } from "@chakra-ui/react";
import { HtmlProse } from "acsiss-ui";

interface TermsMarkdownContainerProps {
  url: string;
}

export const TermsContainer = ({ url }: TermsMarkdownContainerProps) => {
  const { data = "", isLoading } = useQuery<string>(
    ["terms", url],
    () => fetch(url).then((r) => r.text()),
    {
      enabled: Boolean(url),
    }
  );

  if (isLoading) {
    return (
      <SkeletonText w="100%" noOfLines={4} spacing={3} skeletonHeight="2" />
    );
  }

  if (!data) {
    return (
      <Alert status="error">
        <AlertIcon />
        There was an error loading the terms and conditions.
      </Alert>
    );
  }

  return <HtmlProse>{data}</HtmlProse>;
};
