import React, { useEffect, useState } from "react";
import { FullPageLoadingSpinner } from "../full-page-loading-spinner/FullPageLoadingSpinner";

interface PageLazyLoaderProps {
  delay?: number;
}

const PageLazyLoader = ({ delay = 300 }: PageLazyLoaderProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? <FullPageLoadingSpinner /> : null;
};

export default PageLazyLoader;
