import React, { useCallback, useState } from "react";
import { Wizard } from "react-use-wizard";
import { WizardProps } from "react-use-wizard/dist/types";
import { WizardWithFooterContextType, WizardWithFooterState } from "./types";
import { WizardWithFooterContext } from "./WizardWithFooterContext";

interface WizardWithFooterStateProviderProps extends WizardProps {
  children: React.ReactNode;
}

export const WizardWithFooterStateProvider = ({
  children,
  ...wizardProps
}: WizardWithFooterStateProviderProps) => {
  const [state, setState] = useState<WizardWithFooterState>({
    isHidden: false,
    nextIsLoading: false,
    nextIsDisabled: false,
    cancelAlertMessage: undefined,
    nextButtonText: "Next"
  });

  const [onNext, setOnNext] =
    useState<WizardWithFooterContextType["onNext"]>(undefined);

  const [onCancelConfirmed, setOnCancelConfirmed] =
    useState<WizardWithFooterContextType["onCancelConfirmed"]>(undefined);

  const handleSetIsHidden = useCallback((isHidden: boolean) => {
    setState((state) => ({ ...state, isHidden }));
  }, []);
  
  const handleSetNextIsLoading = useCallback((nextIsLoading: boolean) => {
    setState((state) => ({ ...state, nextIsLoading }));
  }, []);
  
  const handleSetNextIsDisabled = useCallback((nextIsDisabled: boolean) => {
    setState((state) => ({ ...state, nextIsDisabled }));
  }, []);
  
  const handleSetCancelAlertMessage = useCallback(
    (cancelAlertMessage?: string) => {
      setState((state) => ({ ...state, cancelAlertMessage }));
    },
    []
    );

  const handleSetNextButtonText= useCallback((nextButtonText: string | undefined) => {
      setState((state) => ({ ...state, nextButtonText }));
    }, []);

  const handleStoreOnNext = useCallback(
    (onNext: WizardWithFooterContextType["onNext"]) => {
      if (onNext === undefined) {
        setOnNext(undefined);
      } else {
        setOnNext(() => onNext);
      }
    },
    []
  );

  const handleStoreOnCancelConfirmed = useCallback(
    (onCancelConfirmed: WizardWithFooterContextType["onCancelConfirmed"]) => {
      if (onCancelConfirmed === undefined) {
        setOnCancelConfirmed(undefined);
      } else {
        setOnCancelConfirmed(() => onCancelConfirmed);
      }
    },
    []
  );

  return (
    <WizardWithFooterContext.Provider
      value={{
        ...state,
        setIsHidden: handleSetIsHidden,
        setNextIsLoading: handleSetNextIsLoading,
        setNextIsDisabled: handleSetNextIsDisabled,
        onNext,
        setOnNext: handleStoreOnNext,
        onCancelConfirmed,
        setOnCancelConfirmed: handleStoreOnCancelConfirmed,
        setCancelAlertMessage: handleSetCancelAlertMessage,
        setNextButtonText: handleSetNextButtonText
      }}
    >
      <Wizard {...wizardProps}>{children}</Wizard>
    </WizardWithFooterContext.Provider>
  );
};
