import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { Company, CompanyVetting } from "../api/types/Company";

interface VerificationModalProps {
  company: Company;
  isOpen: boolean;
  onClose: () => void;
  unverifiedRedirect?: () => void;
  userName?: string;
  onAgree?: () => void;
  isSaving?: boolean;
}

const DetailItem = ({ label, value }: { label: string; value?: string }) => (
  <Box mb={4} w="100%">
    <Text mb={2} fontWeight="bold" color="primary">
      {label}
    </Text>
    <Text>{value}</Text>
  </Box>
);

export const VerificationModal = ({
  company,
  onClose,
  isOpen,
  unverifiedRedirect,
}: VerificationModalProps) => {
  const companyVetting =
    company.companyVettings.find((x) => x.registrationId) ??
    ({
      vettingType: "TaxPractitionersBoardTermsAccepted",
    } as CompanyVetting);

  const isVerified = company.companyVettingStatus == "Green";

  return (
    <Modal size="xl" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Registration Verification of {company?.name}</ModalHeader>
        <ModalCloseButton />
        {isVerified && (
          <>
            <ModalBody pb={6}>
              <Stack
                direction={{ base: "column", md: "row" }}
                justifyContent={"center"}
                sx={{ mb: 12 }}
                spacing={24}
              >
                <VStack alignItems="baseline" rowGap={3}>
                  <DetailItem
                    label="Registration Type"
                    value={companyVetting?.registrationType}
                  />
                  <DetailItem
                    label="Registration Id"
                    value={companyVetting?.registrationId}
                  />
                  <DetailItem
                    label="Registration Name"
                    value={companyVetting?.registrationName}
                  />
                </VStack>
                <VStack alignItems="baseline" rowGap={3}>
                  <DetailItem label="Status" value={companyVetting?.status} />
                  <DetailItem
                    label="Registration Expiry"
                    value={companyVetting?.validUntil}
                  />
                </VStack>
              </Stack>
            </ModalBody>
            <ModalFooter flexDirection="column">
              <HStack>
                <Button onClick={onClose} colorScheme="gray">
                  Close
                </Button>
              </HStack>
            </ModalFooter>
          </>
        )}
        {!isVerified && (
          <>
            <ModalBody pb={6}>
              You are UNVERIFIED. Please complete the onboarding process to send
              and retrieve data.
            </ModalBody>
            <ModalFooter flexDirection="column">
              <HStack>
                <Button onClick={unverifiedRedirect} colorScheme="gray">
                  Complete Onboarding
                </Button>
              </HStack>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
