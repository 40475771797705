import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import { CustomerSearchContainer } from "./CustomerSearchContainer";
import { CustomerSearchContext } from "./CustomerSearchContext";

interface CustomerSearchProviderProps {
  children: JSX.Element;
}

export const CustomerSearchProvider = ({
  children,
}: CustomerSearchProviderProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <CustomerSearchContext.Provider
      value={{
        isOpen,
        onClose,
        onOpen,
      }}
    >
      <>
        {children}
        <CustomerSearchContainer />
      </>
    </CustomerSearchContext.Provider>
  );
};
