import { PaginationState, SortingState } from "@tanstack/react-table";
import { toTitleCase } from "../../utils/toTitleCase";

interface TableFilters {
  searchText?: string;
  sorting?: SortingState;
  pagination?: PaginationState;
}

export const makeQueryString = (
  filters?: TableFilters,
  otherFields?: object
) => {
  var query = new URLSearchParams();
  if (filters?.searchText) {
    query.append("searchString", filters.searchText);
  }

  if (filters?.pagination) {
    const { pagination } = filters;
    query.append("pageNumber", (pagination.pageIndex + 1).toString());
    query.append("pageSize", pagination.pageSize.toString());
  }

  if (filters?.sorting && filters?.sorting.length) {
    const { sorting } = filters;
    query.append("orderDescending", sorting[0].desc ? "true" : "false");
    query.append("orderBy", toTitleCase(sorting[0].id));
  }

  if (otherFields) {
    Object.entries(otherFields).forEach(([key, value]) => {
      if (value) {
        query.append(key, value);
      }
    });
  }

  const queryString = query.toString();
  if (!queryString) {
    return "";
  }

  return `?${queryString}`;
};
