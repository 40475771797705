import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  HStack,
} from "@chakra-ui/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { RequiredBadge } from "./RequiredBadge";

interface FormInputProps extends UseFormRegisterReturn {
  errorMessage?: string;
  label: string;
  showIsRequired?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  disabledMessage?: string;
  testId?: string;
  isDisabled?: boolean;
  type?: "text" | "number";
}

export const FormInput = React.forwardRef(
  (
    {
      errorMessage,
      label,
      showIsRequired: showRequiredBadge,
      autoFocus,
      placeholder,
      disabledMessage,
      testId,
      type = "text",
      ...rest
    }: FormInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const isInvalid = Boolean(errorMessage);
    return (
      <FormControl
        isDisabled={Boolean(disabledMessage)}
        title={disabledMessage}
        isInvalid={isInvalid}
        mb="16px"
      >
        <HStack justifyContent="space-between">
          <FormLabel>{label}</FormLabel>
          {showRequiredBadge && <RequiredBadge />}
        </HStack>
        <Input
          bg="white"
          type={type}
          placeholder={placeholder ?? label}
          autoFocus={autoFocus}
          {...rest}
          ref={ref}
          data-testid={testId}
        />
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
    );
  }
);
