import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

export const BETA_RELEASE_BANNER_HEIGHT = 60;

interface BetaBannerProps {
    isClosed?: boolean
}

export const BetaBanner = (props: BetaBannerProps) => (
  <Box
    height={`${BETA_RELEASE_BANNER_HEIGHT}px`}
    position="fixed"
    zIndex={3}
    top={0}
    right={0}
    left={0}
    w="full"
    bg="#6661c2"
    display="flex"
    alignItems="center"
    justifyContent="center"
    py={3}
    px={10}
  >
    <InfoOutlineIcon color="white" />
    <Text ml={2} color="white">
      {props.isClosed && "Closed "}Beta Release
    </Text>
  </Box>
);
