import { Box, Button, HStack } from "@chakra-ui/react";
import { Result } from "./Result";
import { UnAuthorizedIcon } from "./UnAuthorized";
import { Icon } from "@chakra-ui/react";
import { FaMinusCircle } from "react-icons/fa";

const DisabledIcon = () => {
  return (
    <Icon
      borderRadius={"80px"}
      bg="white"
      boxSize={"80px"}
      as={FaMinusCircle}
      color="red.500"
    />
  );
};

const icon = (
  <Box position="relative">
    <UnAuthorizedIcon />
    <Box position="absolute" right={0} top={0}>
      <DisabledIcon />
    </Box>
  </Box>
);

export const DisabledResult = ({
  disabledAccountType,
  onLogout,
}: {
  disabledAccountType: "company" | "user" | "blockingNewSignUps";
  onLogout: () => void;
}) => {  
  let title = `Your ${
    disabledAccountType === "user" ? "account" : "company"
  } has been disabled.`;
  let subtitle = "Sorry, you are not authorized to access this page.";

  if(disabledAccountType === "blockingNewSignUps") {
    title = "Thanks for signing up! We've added you to the waitlist.";
    subtitle = "We will be in touch soon.";
  }
  
  const extra = (
    <HStack style={{ marginTop: 20 }} spacing={5}>
      <Button
        w="150px"
        colorScheme="messenger"
        as="a"
        href="mailto:help@acsiss.com.au"
      >
        Contact support
      </Button>
      <Button w="150px" colorScheme="gray" onClick={onLogout}>
        Logout
      </Button>
    </HStack>
  );

  return <Result icon={icon} title={title} subtitle={subtitle} extra={extra} />;
};
