import { Box, Text } from "@chakra-ui/react";
import React from "react";

interface LayoutFooterProps {
  version: string;
}

export const LayoutFooter = ({ version }: LayoutFooterProps) => {
  return (
    <Box
      borderTopWidth={1}
      borderStyle={"solid"}
      borderColor={"gray.200"}
      pt={3}
      mt={6}
      pb={3}
      display={{
        base: "block",
        md: "none",
      }}
    >
      <Text fontSize="sm" color="gray.600" textAlign="center">
        Version {version}
      </Text>
    </Box>
  );
};
