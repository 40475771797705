import React from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";

interface ResultProps {
  title: string;
  subtitle: string;
  extra?: React.ReactNode;
  icon: React.ReactNode;
}

export const Result = ({ icon, title, subtitle, extra }: ResultProps) => (
  <VStack spacing={3} p={6}>
    <Box width="251px" height="294px" mb={2}>
      {icon}
    </Box>

    <Heading textAlign="center" color="black.500" size="md" fontWeight="bold">
      {title}
    </Heading>
    <Text color="gray.600" textAlign="center">
      {subtitle}
    </Text>
    {extra}
  </VStack>
);
