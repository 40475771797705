import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../company/useCompany";
import { BillingPastDueBanner } from "./BillingPastDueBanner";
import { isIndividual } from "../wizards/OBSOLETE/AdvisorOnboardingWizardUtils";

export const BillingPastDueBannerContainer = () => {
  const navigate = useNavigate();

  const { company } = useCompany();

  const showBillingPastDueBanner = company && company.isPastDueForBilling;

  const gotoManageCompany = useCallback(() => {
    navigate("/manage-company");
  }, [showBillingPastDueBanner]);

  const gotoManageProfile = useCallback(() => {
    navigate("/profile");
  }, [showBillingPastDueBanner]);

  return (
    <>
      {showBillingPastDueBanner && (
        <BillingPastDueBanner
          onManageCompany={gotoManageCompany}
          onManageProfile={gotoManageProfile}
          isIndividual={isIndividual(company)}
        />
      )}
    </>
  );
};
