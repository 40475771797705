import React from 'react';
import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react';
import { Dict } from '@chakra-ui/utils';

interface AppChakraProviderProps {
  children: JSX.Element;
  theme: Dict;
}

const { ToastContainer } = createStandaloneToast();

export const AppChakraProvider = ({
  children,
  theme,
}: AppChakraProviderProps) => {
  return (
    <>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
      <ToastContainer />
    </>
  );
};
