import React from "react";
import { Prose } from "@nikolovlazar/chakra-ui-prose";

interface Props {
  children: string;
}

export const HtmlProse = ({ children }: Props) => {
  return (
    <Prose>
      <div
        dangerouslySetInnerHTML={{
          __html: children,
        }}
      />
    </Prose>
  );
};
