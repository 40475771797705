import { useQueryClient } from "@tanstack/react-query";
import { ExportLocation } from "../../api/types/ExportLocation";
import { useApiGet, useApiMutation } from "../../api/useApi";
import { useCompany } from "../../company/useCompany";

export const EXPORT_LOCATIONS_CACHE_KEY = "exportLocations";

export const useGetExportLocations = () => {
  const { company } = useCompany();
  const { data: exportLocations = [], isLoading } = useApiGet<ExportLocation[]>(
    {
      url: `/api/v1/company/${company?.id}/export-location`,
      options: {
        queryKey: [EXPORT_LOCATIONS_CACHE_KEY],
        enabled: Boolean(company),
      },
    }
  );

  return {
    exportLocations,
    isLoading: isLoading,
  };
};

export const useGetExportLocationsForCustomer = (customerId: string) => {
  const { company } = useCompany();
  const { data: customerExportLocations = [], isLoading } = useApiGet<
    ExportLocation[]
  >({
    url: `/api/v1/company/${company?.id}/export-location/customer/${customerId}`,
    options: {
      queryKey: [`${EXPORT_LOCATIONS_CACHE_KEY}Customer`],
      enabled: Boolean(company),
    },
  });

  return {
    customerExportLocations,
    isLoading: isLoading,
  };
};

export const useGetExportLocation = (id: string) => {
  const { company } = useCompany();
  return useApiGet<ExportLocation>({
    url: `/api/v1/company/${company?.id}/export-location/${id}`,
    options: {
      queryKey: [EXPORT_LOCATIONS_CACHE_KEY, id],
      enabled: Boolean(company && id),
    },
  });
};

export const useGetExportClientBrands = () => {
  return useApiGet<ClientSoftwareBrand[]>({
    url: `/api/v1/clientSoftwareBrands`,
    options: {
      queryKey: ["clientSoftwareBrands"],
    },
  });
};

export const useSaveExportLocation = () => {
  const queryClient = useQueryClient();
  const mutation = useApiMutation();

  const onMutate = async (
    input: ExportLocation,
    onSuccess?: (resultExportLocation: ExportLocation) => void
  ) => {
    const url = `/api/v1/company/${input.companyId}/export-location/${input.id}`;

    mutation.mutate(
      {
        url,
        input,
        method: "PUT",
      },
      {
        onSuccess: (data: any) => {
          const exportLocationResponseData = data.data as ExportLocation;
          queryClient.invalidateQueries([EXPORT_LOCATIONS_CACHE_KEY]);
          if (onSuccess) {
            onSuccess(exportLocationResponseData);
          }
        },
      }
    );
  };

  return {
    onMutate,
    mutation,
  };
};

export const useDeleteExportLocation = () => {
  const queryClient = useQueryClient();
  const mutation = useApiMutation();
  const { company } = useCompany();

  const onMutate = async (locationId: string) => {
    const url = `/api/v1/company/${company?.id}/export-location/${locationId}`;

    mutation.mutate(
      {
        url,
        method: "DELETE",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([EXPORT_LOCATIONS_CACHE_KEY]);
        },
      }
    );
  };

  return {
    mutation,
    onMutate,
  };
};
