import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import React from "react";
import { loginRequest } from "../authConfig";

export const AuthGuard = ({ children }: { children: JSX.Element }) => (
  <MsalAuthenticationTemplate
    interactionType={InteractionType.Redirect}
    authenticationRequest={loginRequest}
  >
    {children}
  </MsalAuthenticationTemplate>
);
