import React, {useCallback, useRef, useState} from "react";
import {Flex, FlexProps, Input, List, Portal, StackProps, VStack,} from "@chakra-ui/react";
import {useHotkeys} from "react-hotkeys-hook";
import {EntitySearchItem} from "./EntitySearchItem";
import {AnimatePresence, motion, Variants} from "framer-motion";

const backdropVariants: Variants = {
  initial: {
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.15,
      ease: "easeOut",
    },
  },
  enter: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.15,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.15,
      ease: "easeOut",
    },
  },
};

const commandPaletteVariants: Variants = {
  initial: {
    scale: 0.9,
    transition: {
      type: "tween",
      duration: 0.15,
      ease: "easeOut",
    },
  },
  enter: {
    scale: 1,
    transition: {
      type: "tween",
      duration: 0.15,
      ease: "easeOut",
    },
  },
  exit: {
    scale: 0.9,
    transition: {
      type: "tween",
      duration: 0.15,
      ease: "easeOut",
    },
  },
};

const MotionFlex = motion<FlexProps>(Flex);
const MotionVStack = motion<StackProps>(VStack);

interface EntitySearchModalProps<T> {
  items: T[];
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onSelect: (id: T) => void;
  searchText: string;
  onSearchTextChange: (searchText: string) => void;
  renderDescription: (item: T) => React.ReactNode;
  placeholder: string;
}

export function EntitySearchModal<T>({
  items,
  isOpen,
  onClose,
  onOpen,
  onSelect,
  searchText,
  onSearchTextChange,
  renderDescription,
  placeholder,
}: EntitySearchModalProps<T>) {
  const searchRef = useRef<HTMLInputElement>(null);

  const [focusedIndex, setFocusedIndex] = useState(0);

  const close = useCallback(() => {
    onClose();
    setFocusedIndex(0);
    onSearchTextChange("");
  }, [onClose, setFocusedIndex]);

  useHotkeys("ctrl+k, cmd+k", (e: KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onOpen();
  });

  const onKeyDown = useCallback(
      (event: React.KeyboardEvent) => {
        if (event.key === "Escape") {
          close();
        } else if (event.key === "ArrowDown") {
          // Move focus down
          setFocusedIndex((prevIndex) => (prevIndex + 1) % items.length);
        } else if (event.key === "ArrowUp") {
          // Move focus up
          setFocusedIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
        } else if (event.key === "Enter") {
          // Select the focused item
          onSelect(items[focusedIndex]);
          close();
        }
      },
      [close, items, onSelect, focusedIndex]
  );

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <Portal>
          <MotionFlex
            onClick={close}
            variants={backdropVariants}
            initial="initial"
            animate="enter"
            exit="exit"
            position="fixed"
            inset={0}
            bg="whiteAlpha.800"
            _dark={{
              bg: "blackAlpha.800",
            }}
            zIndex="tooltip"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MotionVStack
                variants={commandPaletteVariants}
                initial="initial"
                animate="enter"
                exit="exit"
                mt={{ base: 12, md: 24 }}
                mx={2}
                onClick={(e) => e.stopPropagation()}
                bg="white"
                _dark={{
                  bg: "gray.900",
                }}
                shadow="2xl"
                maxW="3xl"
                maxH={{ base: "40vh", md: "md" }} 
                overflow="hidden" 
                w="full"
                rounded="md"
                borderWidth="1px"
                borderStyle="solid"
            >
              <Input
                  placeholder={placeholder}
                  ref={searchRef}
                  autoFocus
                  value={searchText}
                  onKeyDown={onKeyDown}
                  onChange={(e) => onSearchTextChange(e.target.value)}
                  minHeight="40px" // Prevent height changes
              />
              <List
                  overflowY="auto"
                  w="full"
                  pb={2}
                  px={2}
              >
                {items.map((item, index) => (
                    <EntitySearchItem<T>
                        key={index}
                        item={item}
                        onClick={() => {
                          onSelect(item);
                          close();
                        }}
                        focusedIndex={focusedIndex}
                        index={index}
                        renderDescription={renderDescription}
                    />
                ))}
              </List>
            </MotionVStack>
          </MotionFlex>
        </Portal>
      )}
    </AnimatePresence>
  );
}
