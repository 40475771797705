import { Company } from "../../api/types/Company";
import { Officer } from "../../company/directorQueryHooks";
import { Customer } from "../../customers/types";
import { OnboardingClient } from "./advisor-onboarding-wizard/types/types";

function hasAcceptedLatestTerms(user: User, company: Company) {
  return Boolean(
    user &&
      user.role === "Administrator" &&
      company &&
      !company.hasAcceptedLatestTerms
  );
}

function isBillableCustomer(customer: Customer | undefined) {
  return !(customer && customer.preferredExportLocations.length !== 0);
}

export function isIndividual(company: Company | undefined) {
  return company?.companyPlan.planType === "Individual";
}

function isCompanyOrSoleTrader(company: Company) {
  const companyOrSoleTraderEntityTypeNames = [
    "Australian Private Company",
    "Australian Public Company",
    "Individual/Sole Trader",
  ];
  return (
    company?.companyAbn &&
    companyOrSoleTraderEntityTypeNames.includes(
      company.companyAbn.entityTypeName
    )
  );
}

function isManualGreenIdValidationRequired(company: Company) {
  return (
    company?.hasNotifiedSupportNeedsManualGreenIdValidation &&
    !isCompanyOrSoleTrader(company) &&
    !company?.hasGreenIdBusinessValidation
  );
}

function hasClientsToInvite(clientsToInvite: Array<OnboardingClient>) {
  return clientsToInvite.length > 0;
}

function hasCompletedAllVetting(company: Company) {
  return company?.companyVettingStatus === "Green";
}

function hasAbnNumber(company: Company) {
  return company?.abn || company.companyAbn;
}

function hasAcnNumber(company: Company) {
  return company?.acn;
}

function hasDirectorDob(director: Officer | undefined) {
  return director?.dateOfBirth;
}

function hasChosenSignupPath(company: Company) {
  return company?.shouldBeExcludedFromMyDataSearch !== null;
}

function hasCompletedRegistration(company: Company) {
  return (
    company?.companyVettings.filter(
      (v) => Boolean(v.status) && v.status!.startsWith("Registered")
    ).length > 0
  );
}

// These are all the steps in the wizard
export const enum wizardSteps {
  AcceptTermsStep = "AcceptTermsStep",
  BillingDetailsStep = "BillingDetailsStep",
  VerifyCompanyStep = "VerifyCompanyStep",
  ChooseCompanyTypeStep = "ChooseCompanyTypeStep",
  EnterCompanyDetailsStep = "EnterCompanyDetailsStep",
  EnterCompanyAcnDetailsStep = "EnterCompanyAcnDetailsStep",
  EnterGreenIdBusinessValidationIdStep = "EnterGreenIdBusinessValidationIdStep",
  DirectorRegistrationStep = "DirectorRegistrationStep",
  GreenIdSandboxConfirmStep = "GreenIdSandboxConfirmStep",
  GreenIdDobStep = "GreenIdDobStep",
  GreenIdQrStep = "GreenIdQrStep",
  ChooseClientsStep = "ChooseClientsStep",
  ConfirmConfigStep = "ConfirmConfigStep",
  SetupCompleteStep = "SetupCompleteStep",
}

// Calculates the steps to be shown in the wizard based on the current state of the user, company and customer
export function calculateWizardSteps(
  user: User,
  company: Company,
  customer: Customer | undefined,
  selectedDirector: Officer | undefined,
  clientsToInvite: Array<OnboardingClient>
): Array<string> {
  const steps = new Array<string>();

  if (hasAcceptedLatestTerms(user, company)) {
    steps.push(wizardSteps.AcceptTermsStep);
  }

  if (isBillableCustomer(customer)) {
    steps.push(wizardSteps.BillingDetailsStep);
  }

  steps.push(wizardSteps.VerifyCompanyStep);

  steps.push(wizardSteps.ChooseCompanyTypeStep);

  if (!isIndividual(company)) {
    steps.push(wizardSteps.EnterCompanyDetailsStep);

    if (
      !isCompanyOrSoleTrader(company) &&
      !company?.hasGreenIdBusinessValidation
    ) {
      steps.push(wizardSteps.EnterCompanyAcnDetailsStep);
    }

    if (
      !isCompanyOrSoleTrader(company) &&
      isManualGreenIdValidationRequired(company)
    ) {
      steps.push(wizardSteps.EnterGreenIdBusinessValidationIdStep);
    }

    steps.push(wizardSteps.DirectorRegistrationStep);
  }

  if (window.appConfig.isSandBox) {
    steps.push(wizardSteps.GreenIdSandboxConfirmStep);
  }

  if (!window.appConfig.isSandBox && !hasDirectorDob(selectedDirector)) {
    steps.push(wizardSteps.GreenIdDobStep);
  }

  if (!window.appConfig.isSandBox) {
    steps.push(wizardSteps.GreenIdQrStep);
  }

  steps.push(wizardSteps.ChooseClientsStep);

  if (hasClientsToInvite(clientsToInvite)) {
    steps.push(wizardSteps.ConfirmConfigStep);
  }

  steps.push(wizardSteps.SetupCompleteStep);

  //Finally set the properties in state to be used by the wizard
  return steps;
}

// Calculates the starting step to be shown in the wizard based on the current state of the user, company and customer
export function calculateWizardStartStep(
  user: User,
  company: Company,
  selectedDirector: Officer | undefined
): string {
  if (hasAcceptedLatestTerms(user, company)) {
    return wizardSteps.AcceptTermsStep;
  }

  if (!hasCompletedRegistration(company)) return wizardSteps.VerifyCompanyStep;

  if (!hasChosenSignupPath(company)) return wizardSteps.ChooseCompanyTypeStep;

  if (!hasCompletedAllVetting(company)) {
    //Company Vetting
    if (!isIndividual(company)) {
      if (!hasAbnNumber(company)) return wizardSteps.EnterCompanyDetailsStep;

      // manually entered greenid validation id
      if (isManualGreenIdValidationRequired(company))
        return wizardSteps.EnterGreenIdBusinessValidationIdStep;

      // manually entered acn
      if (
        !hasAcnNumber(company) &&
        !isCompanyOrSoleTrader(company) &&
        !company?.hasGreenIdBusinessValidation
      )
        return wizardSteps.EnterCompanyAcnDetailsStep;

      //Select director/company office holder
      if (!selectedDirector) return wizardSteps.DirectorRegistrationStep;
    }

    //If sandbox, skip greenid steps
    if (window.appConfig.isSandBox)
      return wizardSteps.GreenIdSandboxConfirmStep;

    //Enter director date of birth (Note for individuals this will always be asked because an individual will not have a dob)
    if (!window.appConfig.isSandBox && !hasDirectorDob(selectedDirector))
      return wizardSteps.GreenIdDobStep;

    if (!window.appConfig.isSandBox && hasDirectorDob(selectedDirector))
      return wizardSteps.GreenIdQrStep;
  }

  // user has already completed the onboarding process
  return wizardSteps.ChooseClientsStep;
}
