import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react';

const baseOptions: UseToastOptions = {
  isClosable: true,
  position: 'top-right',
};

export const useToast = () => {
  const toast = useChakraToast();

  const errorToast = (options: UseToastOptions) => {
    toast({
      ...baseOptions,
      ...options,
      status: 'error',
    });
  };

  const successToast = (options: UseToastOptions) => {
    toast({
      ...baseOptions,
      ...options,
      status: 'success',
    });
  };

  const infoToast = (options: UseToastOptions) => {
    toast({
      ...baseOptions,
      ...options,
      status: 'info',
    });
  };

  const warningToast = (options: UseToastOptions) => {
    toast({
      ...baseOptions,
      ...options,
      status: 'warning',
    });
  };

  return { successToast, errorToast, infoToast, warningToast };
};
