import React from "react";
import { Button, Center } from "@chakra-ui/react";
import { Result } from "../result/Result";
import { UnAuthorizedIcon } from "../result/UnAuthorized";
import { ErrorResultIcon } from "../result/ErrorResultIcon";

interface State {
  hasError: boolean;
  error: null | any;
}

interface Props {
  children: JSX.Element;
  onRetryLogin: () => void;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = { hasError: false, error: null };
  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }
  render() {
    const { hasError, error } = this.state;
    const { onRetryLogin } = this.props;
    if (hasError) {
      console.log(error);
      const accountWasDeleted =
        (error?.errorMessage ?? "").includes("AADB2C99002") ||
        (error?.errorMessage ?? "").includes("This user does not exist");

      if (accountWasDeleted) {
        return (
          <Center h="100vh">
            <Result
              icon={<UnAuthorizedIcon />}
              title="Not Authorised"
              subtitle={"Your account has been deleted, please sign up again"}
              extra={
                <Button colorScheme="blue" onClick={onRetryLogin}>
                  Try again
                </Button>
              }
            />
          </Center>
        );
      }

      if (error?.errorCode === "access_denied") {
        const isCancelledLogin = (error?.errorMessage ?? "").includes(
          "AADB2C90091"
        );

        const subtitle = isCancelledLogin
          ? "Login was cancelled, please try again"
          : "Sorry, you are not authorized to access this page.";
        return (
          <Center h="100vh">
            <Result
              icon={<UnAuthorizedIcon />}
              title="Not Authorised"
              subtitle={subtitle}
              extra={
                <Button colorScheme="blue" onClick={onRetryLogin}>
                  Try again
                </Button>
              }
            />
          </Center>
        );
      }

      return (
        <Center h="100vh">
          <Result
            icon={<ErrorResultIcon />}
            title="500"
            subtitle="Sorry, something went wrong."
            extra={
              <Button
                colorScheme="blue"
                onClick={() => {
                  // @ts-ignore
                  window.location.reload();
                }}
              >
                Refresh
              </Button>
            }
          />
        </Center>
      );
    }
    return this.props.children;
  }
}
