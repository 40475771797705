import { useToast } from "acsiss-ui";
import { Company } from "../api/types/Company";
import { useApiGet, useApiMutation } from "../api/useApi";
import { useAuth } from "../auth/useAuth";
import { useCompany } from "./useCompany";

export const useGetLatestTermsDetails = () => {
  const { user } = useAuth();
  return useApiGet<{ version: string; url: string }>({
    url: `/api/v1/terms/latest`,
    options: {
      queryKey: ["latestTerms"],
      enabled: Boolean(user),
    },
  });
};

export const useAcceptTermsMutation = () => {
  const mutation = useApiMutation();
  const { company, setCompany } = useCompany();
  const { successToast } = useToast();

  const onMutate = (version: string, onSuccess: () => void) => {
    mutation.mutate(
      {
        input: { version },
        url: `/api/v1/terms/${company?.id}/accept-terms`,
        method: "POST",
      },
      {
        onSuccess: (data) => {
          onSuccess();
          // @ts-ignore
          setCompany(data.data as Company);
          successToast({
            title: "Terms Accepted",
          });
        },
      }
    );
  };

  return { mutation, onMutate };
};
