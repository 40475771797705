import React from "react";
import { Box, HStack, IconButton, useDisclosure } from "@chakra-ui/react";
import { NotificationsMenu } from "../components/notifications/NotificationsMenu";
import { UserMenu } from "../components/user-menu/UserMenu";
import { CustomerSearchButton } from "../customers/customer-search-modal/CustomerSearchButton";
import {
  useGetUnreadNotifications,
  useMarkAllNotificationsAsReadMutation,
  useMarkNotificationAsReadMutation,
} from "../pages/log-notifications/useNotifications";
import { useCustomerSearch } from "../customers/customer-search-modal/useCustomerSearch";
import { Breadcrumbs } from "./Breadcrumbs";
import { SidebarMenuLinkItem } from "../components/sidebar-menu/SidebarMenu";
import { HamburgerIcon } from "@chakra-ui/icons";
import { SidebarDrawer } from "./SidebarDrawer";

const shadow = "7px 5px 11px 2px rgb(112 144 176 / 18%)";

interface LayoutTopBarProps {
  linkItems: SidebarMenuLinkItem[];
}

export const LayoutTopBar = ({ linkItems }: LayoutTopBarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onMarkRead } = useMarkNotificationAsReadMutation();
  const { onMarkAllRead } = useMarkAllNotificationsAsReadMutation();
  const { unReadNotifications, unreadCount } = useGetUnreadNotifications();
  const { onOpen: onOpenCustomerSearch } = useCustomerSearch();

  const breadcrumsUI = <Breadcrumbs />;

  return (
    <>
      <SidebarDrawer isOpen={isOpen} onClose={onClose} linkItems={linkItems} />
      <HStack justifyContent="space-between" my={4}>
        <Box flex={1}>
          <Box display={{ base: "none", md: "block" }}>{breadcrumsUI}</Box>
          <Box display={{ base: "block", md: "none" }}>
            <IconButton
              aria-label="Open Menu"
              size="lg"
              icon={<HamburgerIcon />}
              onClick={onOpen}
            />
          </Box>
        </Box>

        <HStack
          spacing={3}
          bg="white"
          borderRadius="30px"
          p={2}
          shadow={shadow}
        >
          <Box w={{ base: "44px", md: "264px" }} ml={2}>
            <CustomerSearchButton onOpen={onOpenCustomerSearch} />
          </Box>
          <NotificationsMenu
            notifications={unReadNotifications}
            unreadCount={unreadCount}
            onMarkRead={onMarkRead}
            onMarkAllRead={onMarkAllRead}
          />
          <UserMenu />
        </HStack>
      </HStack>
      <Box display={{ base: "block", md: "none" }}>
        <Box mt={2} mb={2}>
          {breadcrumsUI}
        </Box>
      </Box>
    </>
  );
};
