import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { SidebarMenuLinkItem } from "../components/sidebar-menu/SidebarMenu";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

function NavLink({
  title,
  to,
  icon,
  onClick,
  asLink,
}: SidebarMenuLinkItem & { onClick: () => void; asLink: boolean }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  if (asLink)
    return (
      <HStack
        onClick={onClick}
        color={match ? "blue.500" : "black"}
        spacing={2}
        as={Link}
        to={to}
      >
        <Icon fontSize="28px" as={icon} />
        <Text>{title}</Text>
      </HStack>
    );

  return (
    <HStack
      onClick={onClick}
      color={match ? "blue.500" : "black"}
      spacing={2}
      as={"a"}
      href={to}
    >
      <Icon fontSize="28px" as={icon} />
      <Text>{title}</Text>
    </HStack>
  );
}

interface SidebarDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  linkItems: SidebarMenuLinkItem[];
}

export const SidebarDrawer = ({
  isOpen,
  onClose,
  linkItems,
}: SidebarDrawerProps) => (
  <Drawer placement="left" isOpen={isOpen} onClose={onClose}>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>ACSISS Adviser</DrawerHeader>

      <DrawerBody>
        <VStack alignItems="baseline" spacing={4}>
          {linkItems.map((l) => {
            if (l.to.startsWith("mailto:")) {
              return (
                <NavLink key={l.to} {...l} onClick={onClose} asLink={false} />
              );
            }
            return (
              <NavLink key={l.to} {...l} onClick={onClose} asLink={true} />
            );
          })}
        </VStack>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);
