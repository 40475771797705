import { useQueryClient } from "@tanstack/react-query";
import { useApiGet, useApiMutation } from "../../api/useApi";
import { useAuth } from "../../auth/useAuth";
import { Notification } from "../../components/notifications/types";
import {
  PagedResult,
  makePaginationPropsFromQueryData,
  useTableFilters,
} from "../../../../../libs/shared-ui/src/main";
import { makeQueryString } from "../../api/utils/makeQueryString";
import { useCompany } from "../../company/useCompany";

const NOTFICATIONS_CACHE_KEY = "notifications";

type PagedNotifications = PagedResult<Notification>;

export const useGetAllNotifications = () => {
  const { user } = useAuth();
  const { company } = useCompany();

  const {
    searchText,
    onChangeSearchText,
    debouncedSearchText,
    pagination,
    onChangePagination,
    sorting,
    sortingProps,
  } = useTableFilters({
    defaultSorting: [
      {
        id: "dateCreated",
        desc: true,
      },
    ],
  });

  const queryString = makeQueryString(
    {
      sorting,
      pagination,
      searchText: debouncedSearchText,
    },
    {
      unreadOnly: "false",
    }
  );

  const allNotificationsResult = useApiGet<PagedNotifications>({
    url: `/api/v1/company/${company?.id}/user/${user?.id}/notifications${queryString}`,
    options: {
      queryKey: [
        NOTFICATIONS_CACHE_KEY,
        "notUnreadOnly",
        debouncedSearchText,
        pagination,
        sorting,
      ],
      enabled: Boolean(user?.id && company?.id),
      // show existing data in table until we get to the new page
      keepPreviousData: true,
    },
  });

  const paginationProps = makePaginationPropsFromQueryData(
    pagination,
    onChangePagination,
    allNotificationsResult.data
  );

  const notifications = allNotificationsResult.data?.data ?? [];

  return {
    searchText,
    debouncedSearchText,
    onChangeSearchText,
    sortingProps,
    paginationProps,
    notifications,
    ...allNotificationsResult,
  };
};

export const useGetUnreadNotifications = () => {
  const { user } = useAuth();
  const { company } = useCompany();

  const { data: unReadNotificationsData } = useApiGet<PagedNotifications>({
    url: `/api/v1/company/${company?.id}/user/${user?.id}/notifications?unreadOnly=true&orderDescending=true&orderBy=DateCreated`,
    options: {
      queryKey: [NOTFICATIONS_CACHE_KEY, "unread"],
      enabled: Boolean(user?.id && company?.id),
    },
  });

  const unReadNotifications = unReadNotificationsData
    ? unReadNotificationsData.data
    : [];

  const unreadCount = (() => {
    const count = unReadNotifications.length;
    if (!count) return 0;
    if (count > 10) return "10+";
    return count;
  })();

  return {
    unReadNotifications: unReadNotifications.slice(0, 10).map((d) => {
      return {
        ...d,
      };
    }),
    unreadCount,
  };
};

export const useMarkNotificationAsReadMutation = () => {
  const { user } = useAuth();
  const { company } = useCompany();
  const mutation = useApiMutation();
  const queryClient = useQueryClient();
  const handleMarkNotificationAsRead = (id: string) => {
    mutation.mutate(
      {
        input: {},
        url: `/api/v1/company/${company?.id}/user/${user?.id}/notifications/${id}/has-been-read`,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([NOTFICATIONS_CACHE_KEY]);
        },
      }
    );
  };

  return {
    mutation,
    onMarkRead: handleMarkNotificationAsRead,
  };
};

export function useMarkAllNotificationsAsReadMutation() {
  const { user } = useAuth();
  const { company } = useCompany();
  const mutation = useApiMutation();
  const queryClient = useQueryClient();
  const handleMarkAllNotificationsAsRead = () => {
    mutation.mutate(
      {
        input: {},
        url: `/api/v1/company/${company?.id}/user/${user?.id}/notifications/clear-notifications`,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([NOTFICATIONS_CACHE_KEY]);
        },
      }
    );
  };

  return {
    mutation,
    onMarkAllRead: handleMarkAllNotificationsAsRead,
  };
}
