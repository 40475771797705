import { useToast } from "acsiss-ui";
import { ApiError } from "../api/api";
import { Company } from "../api/types/Company";
import { useApiMutation } from "../api/useApi";
import { useAuth } from "../auth/useAuth";
import { useCompany } from "./useCompany";

interface UpdateCompanyLogoDto {
  LogoBase64: string;
}

export const useUpdateCompanyLogo = () => {
  const { company, setCompany } = useCompany();
  const mutation = useApiMutation({
    // turn off default error toast
    onError: () => {},
  });
  const { errorToast } = useToast();
  const handleMutate = (
    input: UpdateCompanyLogoDto,
    onSuccess?: () => void,
    onError?: (error: ApiError) => void
  ) => {
    mutation.mutate(
      {
        input,
        url: `/api/v1/company/${company?.id}/updatelogo`,
        method: "PUT",
      },
      {
        // @ts-ignore
        onSuccess: (data: { data: Company }) => {
          setCompany(data.data);
          if (onSuccess) {
            onSuccess();
          }
        },
        onError: (error: ApiError) => {
          if (onError) {
            onError(error);
          } else {
            errorToast({
              title: "Something went wrong",
              description: error.errors.join("\n"),
            });
          }
        },
      }
    );
  };

  return { mutation, onMutate: handleMutate };
};
